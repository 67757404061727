import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import Logo from "../../assets/logo.png";

function Header({ children }) {
  return (
    <>
      <div class="h-screen">
        <nav class="flex items-center justify-between px-2 py-3 mb-1">
          <div class="flex xl:w-[1140px] 2xl:w-[1140px] w-full mx-auto justify-between">
            <div class="w-full relative flex justify-start  lg:static lg:justify-start">
              <img
                src={Logo}
                class="w-[200px] h-auto auto-[300/82]"
                alt="logo"
              />
            </div>
            <div class="w-full flex justify-end">
              <h1 class="text-lg my-2 ml-5 flex text-[#121F3E] font-semibold">
                <FaPhoneAlt class="m-1 fill-blue-600" />1 (844) 790-1253
              </h1>
            </div>
          </div>
        </nav>
        <div>{children}</div>
        <footer class="flex xl:w-[1140px] 2xl:w-[1140px] mx-auto mb-3 pb-6">
          <div class="flex w-full border-t  pt-10  flex-col sm:flex-row justify-between px-2">
            <div class="flex mx-auto sm:mx-0">
              <h2 class="text-base font-medium text-[#6D7D93] font-sans">
                @2021 Hero Life Insurance, All rights reserved.
              </h2>
            </div>

            <div class="flex mx-auto sm:mx-0 mt-4 sm:mt-0">
              <a
                class="text-base font-normal text-[#6D7D93] font-sans mr-8"
                href="terms"
              >
                Terms
              </a>
              <a
                class="text-base font-normal text-[#6D7D93] font-sans mr-8"
                href="privacy"
              >
                Privacy
              </a>
              <a
                class="text-base font-normal text-[#6D7D93] font-sans"
                href="privacy"
              >
                Contact
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Header;
