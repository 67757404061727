// const API_BASE_URL = "http://localhost:8000/"; // Local Server
const API_BASE_URL = "https://0dbh3r78f8.execute-api.ca-central-1.amazonaws.com/dev/"; // Remove Server


const API_END_POINTS = {
  ADD_FORM: API_BASE_URL + "fillForm",
  UPLOAD_IMAGE: API_BASE_URL + 'uploadImage',
  GET_ADDRESS: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws',
};

export { API_BASE_URL, API_END_POINTS };
