import React from 'react';
import { BsFacebook, BsCheckLg } from "react-icons/bs";

function ThankYou() {
  return (
    <div class="flex w-full mx-auto mb-8 py-1">
      <div class="flex w-full xl:w-[1140px] mx-auto mt-40 flex-col ">
        <div class="flex mx-auto mb-[10px]">
          <h2 class="flex text-[#121f3e] text-[40px] font-bold font-sans">
            Congrats! We received your info.
          </h2>
        </div>
        <div class="flex mx-auto mb-[10px]">
          <h2 class="flex text-[#6D7D93] text-[16px] font-normal font-sans">
            One of our licensed Insurance Advisor will contact you soon. In the mean time, let’s connect on Facebook.
          </h2>
        </div>
        <div class="flex mx-auto my-[35px]">
          <img
            class="auto-[399/283] md:w-[250px] w-[200px] h-auto object-contain"
            src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/Screenshot_1.png"
            alt=""
          />
        </div>
        <div class="flex pt-[60px] mx-auto">
          <a href='https://www.facebook.com/herolifeinsurance' class="flex bg-[#1D86FF] w-[184px] h-[47px] justify-center items-center rounded">
            <BsFacebook class="m-1 fill-white" />
            <span class="text-white text-[16px] font-medium font-sans ml-[12px]">
              Let's Connect
            </span>
          </a>
        </div>

      </div>
    </div>
  );
}

export default ThankYou;
