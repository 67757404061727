import React, { useState } from "react";
import Header from "../../components/header";
import InputField from "../../components/inputField";

const ContactComponent = () => {
  const [contactSales] = useState([
    { name: "PR & Media", address: "media@herolifeinsurance.ca" },
    { name: "Partnership", address: "sales@herolifeinsurance.ca" },
    { name: "Client Support", address: "support@herolifeinsurance.ca" },
    { name: "Other Inquiries", address: "hello@herolifeinsurance.ca" },
  ]);

  const [formValues, setFormValues] = useState({
    fullName: "",
    contactNumber: "",
    email: "",
    howCanHelp: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    contactNumber: false,
    email: false,
    howCanHelp: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <div class="w-full mx-auto mb-20">
      <section class="w-full mx-auto py-[50px] items-center mb-[20px]  p-[10px]">
        <h2 class="text-[#121F3E] md:text-[52px] text-[30px] font-bold font-sans mx-auto mb-[20px]">
          Contact us
        </h2>
        <span class="mb-[50px] text-[#96A0B5] font-medium text-[18px] font-sans">
          Questions, Comments, or Feedback? You’re in the right place!
        </span>
      </section>
      <section class="flex w-full justify-between md:flex-row-reverse flex-col">
        <div class="w-full p-[15px] pb-0">
          <h4 class="flex `text-[#3A3A3A] md:text-[32px] text-[20px] font-bold font-sans mb-5">
            Get in Touch
          </h4>
          <div class="flex px-[5px] mb-6 w-full flex-col">
            <label class="flex text-[#121F3E] text-[18px] font-medium font-sans mb-2">
              Full Name
            </label>
            <InputField
              type={"text"}
              class="md:w-4/5 w-full"
              placeholder="Enter Your First and Last Name"
              name="fullName"
              value={formValues.fullName}
              onChange={handleChange}
            />
          </div>
          <div class="flex px-[5px] mb-6 w-full flex-col">
            <label class="flex text-[#121F3E] text-[18px] font-medium font-sans mb-2">
              Contact Number
            </label>
            <InputField
              type={"text"}
              class="md:w-4/5 w-full"
              placeholder="Enter Your Phone Number"
              name="contactNumber"
              value={formValues.contactNumber}
              onChange={handleChange}
            />
          </div>
          <div class="flex px-[5px] mb-6 w-full flex-col">
            <label class="flex text-[#121F3E] text-[18px] font-medium font-sans mb-2">
              Email
            </label>
            <InputField
              type={"email"}
              required
              class="md:w-4/5 w-full"
              placeholder="Enter Your Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
          </div>
          <div class="flex px-[5px] mb-6 w-full flex-col">
            <label class="flex text-[#121F3E] text-[18px] font-medium font-sans mb-2">
              How Can We Help?
            </label>
            <textarea
              class="flex md:w-4/5 w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-40 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              placeholder="Let us know how we help you?"
              name="howCanHelp"
              value={formValues.howCanHelp}
              onChange={handleChange}
            />
          </div>
          <div class="flex px-[5px] md:w-4/5 w-full justify-end">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 md:w-[150px] w-full text-white font-bold font-sans rounded-full"
              // onClick={() => nextButtonClicked()}
            >
              Send Message
            </button>
          </div>
        </div>
        <div class="bg-gray-100 w-full">
          <div class="md:pb-[20px] md:pl-[125px] md:pr-[35px] md:pt-[50px] p-[15px]">
            <div class="w-full mb-[20px] ">
              <h4 class="flex w-full mb-5 text-[32px] font-bold font-sans text-[#3A3A3A]">
                Contact Sales
              </h4>
            </div>
            <div class="w-full mb-[20px]">
              <h4 class="flex w-full mb-5 text-[16px] font-medium font-sans text-[#96A0B5] text-left">
                Please use the form on your right to contact our sales team.
              </h4>
            </div>
            <div class="w-full mb-[20px]">
              <h4 class="flex w-full mb-5 text-[16px] font-medium font-sans text-[#96A0B5] text-left">
                For other inquiries please follow the links below
              </h4>
            </div>
            <section>
              {contactSales.map((item) => {
                return (
                  <div class="flex flex-row justify-between items-center mb-5">
                    <h2 class="text-[#121F3E] font-semibold text-[16px] font-sans">
                      {item.name}
                    </h2>
                    <span class="text-[#1E86FF] text-[16px] font-semibold font-sans ml-4 w-1/2 text-left">
                      {item.address}
                    </span>
                  </div>
                );
              })}
            </section>
            <div class="mt-14 mb-4">
              <h2 class="w-full flex text-[#121F3E] text-[18px] font-semibold font-sans mb-4">
                Eastern Canada
              </h2>
              <h2 class="flex w-full text-left text-[#96A0B5] text-[18px] font-normal font-sans">
                2235 Sheppard Ave East, Unit 1200
                <br />
                North York, ON M2J 5B5
              </h2>
            </div>
            <div class="mt-6">
              <h2 class="w-full flex text-[#121F3E] text-[18px] font-semibold font-sans mb-4">
                Western Canada
              </h2>
              <h2 class="flex w-full text-left text-[#96A0B5] text-[18px] font-normal font-sans">
                1050 W Pender St #2050,
                <br />
                Vancouver, BC V6E 3S7
              </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
function ContactUs() {
  return <Header>{ContactComponent()}</Header>;
}

export default ContactUs;
