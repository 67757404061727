import React from 'react';
import { Formik, Field, Form, ErrorMessage, useField} from "formik";

function InputField(props) {
  return (
    <div>
      <input
        class={`flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500 ${props.class}`}
        type={props.type}
        required={props.required}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      />
    </div>
  );
}

export default InputField;
