import React, { useState } from "react";
import Header from "../../components/header";
import SelectOption from "../../components/selectOption";
import CircleNumber from "../../components/circleNumber";
import CardView from "../../components/cardView";
import { Dates, OurClients, HelpDifficults } from "../../utils";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuoteInput from "../../components/quoteInput";
import ScreenSize from "../../components/screenSize";
import {
  faHandHoldingMedical,
  faHandPeace,
  faBusinessTime,
  faClipboardListCheck,
  faAward,
  faBookSpells,
  faAngleUp,
  faAngleDown,
  faCheckCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useCollapse from 'react-collapsed';
import { useNavigate } from "react-router-dom";
import { FcPrevious, FcNext } from "react-icons/fc";
import Logo from "../../assets/logo.png";


const LifeComponent = () => {
  return (
    <div class="flex w-full mx-auto mb-8 py-1 ">
      <div class="bg-white w-full xl:w-[1140px] mx-auto mt-20 flex lg:flex-row flex-col ">
        <div class="w-full mx-[10px]">
          <div class="w-full my-6 xl:max-w-[562px] pr-[45px]">
            <p class="text-[#121f3e] lg:text-[40px] sm:text-[30px] text-[20px] font-bold font-plex text-left flex items-center mt-2 mb-4">
              Life is too short to worry about money
            </p>
            <div class="flex flex-row mt-2 items-center h-[38px]">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="w-[26px] h-[26px] mr-[6px]"
                color="#1e86ff"
              />
              <p class="text-[#6d7d93]  text-[18px] font-normal font-plex lg:text-left text-center ml-[5px]">
                Get guaranteed approval final expense insurance
              </p>
            </div>
            <div class="flex flex-row mt-2 items-center h-[38px]">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="w-[26px] h-[26px] mr-[6px]"
                color="#1e86ff"
              />
              <p class="text-[#6d7d93]  text-[18px] font-normal font-plex lg:text-left text-center ml-[5px]">
                Be the caring protector your family deserves and take a load of
                their back
              </p>
            </div>
            <div class="flex flex-row mt-2 items-center h-[38px]">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="w-[26px] h-[26px] mr-[6px]"
                color="#1e86ff"
              />
              <p class="text-[#6d7d93]  text-[18px] font-normal font-plex lg:text-left text-center ml-[5px]">
                Get personalized advise with our Senior Licensed Advisors
              </p>
            </div>
          </div>
          <div class="flex  mt-4  w-full lg:justify-start justify-center">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-4"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
        <div class="flex w-full  items-center justify-center">
          <img
            class="auto-[800/800] w-[400px] h-auto object-contain rounded-full"
            src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/qr7rfIthbvc-1024x1024.jpg"
            alt=""
          />
        </div>
        
      </div>
    </div>
  );
};
// hand-holding-medical# hand-holding-medical## hand-holding-medical##
const FinancialComponent = () => {
  return (
    <div class="flex w-full mx-auto mb-8 mt-40 flex-col max-w-[1140px]">
      <p class="text-[#121f3e] lg:text-[40px] sm:text-[30px] text-[20px] font-bold font-plex text-center flex items-center mt-2 mx-auto">
        Your Loved ones
        <br />
        Deserve Financial Security
      </p>
      <div class="md:w-2/3 w-full items-center mx-[229px] p-[10px] mt-9">
        <div class="flex md:flex-row flex-col-reverse w-full items-center">
          <div class="flex w-[415px]">
            <p class="text-[#6d7d93]  text-[16px] font-medium font-plex md:text-left text-center md:mt-0 mt-12">
              Get no medical coverage even if you have an existing condition
            </p>
          </div>
          <div class="w-[215px] h-[138px] flex items-center ml-6 p-[10px]">
            <div class="flex w-[112px] h-[112px] items-center justify-center">
              <FontAwesomeIcon
                icon={faHandHoldingMedical}
                className="w-[50px] h-[50px] hover:rotate-6 hover:scale-[1.1]  cursor-pointer duration-300"
                color="#ff985f"
              />
            </div>
          </div>
        </div>
        <div class="flex md:flex-row flex-col-reverse w-full items-center">
          <div class="flex w-[415px]">
            <p class="text-[#6d7d93]  text-[16px] font-medium font-plex md:text-left text-center md:mt-0 mt-12">
              Get the best terms for the best price based on your unique situation
            </p>
          </div>
          <div class="w-[215px] h-[138px] flex items-center ml-6 p-[10px]">
            <div class="flex w-[112px] h-[112px] items-center justify-center">
              <FontAwesomeIcon
                icon={faHandPeace}
                className="w-[50px] h-[50px] hover:rotate-6 hover:scale-[1.1]  cursor-pointer duration-300"
                color="#ff985f"
              />
            </div>
          </div>
        </div>
        <div class="flex md:flex-row flex-col-reverse w-full items-center">
          <div class="flex w-[415px]">
            <p class="text-[#6d7d93]  text-[16px] font-medium font-plex md:text-left text-center md:mt-0 mt-12">
              Get complementary and customizable Last Will and Testament services
              FREE!
            </p>
          </div>
          <div class="w-[215px] h-[138px] flex items-center ml-6 p-[10px]">
            <div class="flex w-[112px] h-[112px] items-center justify-center">
              <FontAwesomeIcon
                icon={faBusinessTime}
                className="w-[50px] h-[50px] hover:rotate-6 hover:scale-[1.1]  cursor-pointer duration-300"
                color="#ff985f"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-20 mx-auto"
        // onClick={() => nextButtonClicked()}
      >
        Get a Quote
      </button>
    </div>
  );
};

const ProtectFamilyComponent = () => {
  return (
    <div class="flex w-full mx-auto py-1 flex-col mt-40 mb-40">
      <p class="text-[#121f3e]  lg:text-[40px] sm:text-[30px] text-[20px] font-bold font-plex flex items-center mt-2 mb-4 mx-auto text-center">
        It's time to protect your family
      </p>
      <div class="flex mx-auto  md:flex-row flex-col max-w-[1140px] w-full justify-between p-[10px] mt-10">
        <div class="flex flex-col md:w-1/3 w-full rounded-2xl shadow-[0_8px_24px_rgba(0,0,0,0.05)] p-[35px] pt-[10px] items-center">
          <div class="z-10 ml-14">
            <h2 class="flex rounded-full bg-[#ef600d] w-[35px] h-[35px] items-center justify-center ">
              <span class="text-[#000] text-[21px] font-semibold">1</span>
            </h2>
          </div>
          <div class="mb-[10px] -mt-6">
            <div class="rounded-full p-[25px] bg-[#fff5f0] hover:scale-[1.1] duration-300 cursor-pointer">
              <FontAwesomeIcon
                icon={faClipboardListCheck}
                className="w-[50px] h-[50px] "
                color="#ff985f"
              />
            </div>
          </div>
          <p class="text-[#121f3e]  text-[16px] font-semibold font-plex  text-center">
            Choose the right coverage
          </p>
          <p class="text-[#6d7d93]  text-[14px] font-normal font-plex  text-center">
            Just answer some questions
          </p>
        </div>
        <div class="flex items-center mx-3">
          <span class="flex border-t-2 border-gray-200 border-dashed md:min-w-[18px] xl:w-[60px] lg:w-[30px] min-w-0"></span>
        </div>
        <img
          class="w-45 h-20 md:w-0 md:h-0 object-contain my-[10px]"
          src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/dot.png"
          alt=""
        />
        <div class="flex flex-col rounded-2xl md:w-1/3 w-full shadow-[0_8px_24px_rgba(0,0,0,0.05)] p-[35px] pt-[10px] items-center">
          <div class="z-10 ml-14">
            <h2 class="flex rounded-full bg-[#fcd549] w-[35px] h-[35px] items-center justify-center ">
              <span class="text-[#000] text-[21px] font-semibold">2</span>
            </h2>
          </div>
          <div class="mb-[10px] -mt-6">
            <div class="rounded-full p-[25px] bg-[#fffbed] hover:scale-[1.1] duration-300 cursor-pointer">
              <FontAwesomeIcon
                icon={faAward}
                className="w-[50px] h-[50px] "
                color="#fcd549"
              />
            </div>
          </div>
          <p class="text-[#121f3e]  text-[16px] font-semibold font-plex  text-center">
            Find the best rates
          </p>
          <p class="text-[#6d7d93]  text-[14px] font-normal font-plex  text-center">
            Let our Advisors shop for you
          </p>
        </div>
        <div class="flex items-center mx-3">
          <span class="flex border-t-2 border-gray-200 border-dashed md:min-w-[18px] xl:w-[60px] lg:w-[30px] min-w-0"></span>
        </div>
        <img
          class="w-45 h-20 md:w-0 md:h-0 object-contain my-[10px]"
          src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/dot.png"
          alt=""
        />
        <div class="flex flex-col md:w-1/3 w-full rounded-2xl shadow-[0_8px_24px_rgba(0,0,0,0.05)] p-[35px] pt-[10px] items-center">
          <div class="z-10 ml-14">
            <h2 class="flex rounded-full bg-[#31bfde] w-[35px] h-[35px] items-center justify-center ">
              <span class="text-[#000] text-[21px] font-semibold">3</span>
            </h2>
          </div>
          <div class="mb-[10px] -mt-6">
            <div class="rounded-full p-[25px] bg-[#eaf9fc] hover:scale-[1.1] duration-300 cursor-pointer">
              <FontAwesomeIcon
                icon={faBookSpells}
                className="w-[50px] h-[50px] "
                color="#31bfde"
              />
            </div>
          </div>
          <p class="text-[#121f3e]  text-[16px] font-semibold font-plex  text-center">
            Receive your policy
          </p>
          <p class="text-[#6d7d93]  text-[14px] font-normal font-plex  text-center">
            Enjoy the peace of mind
          </p>
        </div>
      </div>
      <button
        class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-20 mx-auto"
        // onClick={() => nextButtonClicked()}
      >
        Get a Quote
      </button>
    </div>
  );
};

const LoginAddForm = (navigate) => {
  return (
    <div class="bg-[url(https://herolifeinsurance.ca/wp-content/uploads/2021/12/Screenshot_8.png)] bg-no-repeat bg-center py-20 px-2 h-full">
      <div class="w-[520px] py-10">
        <QuoteInput onNext={() => navigate("/thank-you")}/>
      </div>
    </div>
  );
};

const HomeMainView = (navigate) => {
  return (
    <div class="flex w-full mx-auto mb-8 py-1">
      <div class="bg-white flex w-full xl:w-[1140px] mx-auto mt-20 lg:flex-row flex-col">
        <div class="flex flex-col w-full p-[1px] xl:max-w-[627px] lg:min-w-[560px]">
          <div class="flex mx-auto flex-col w-full my-6">
            <h2 class="text-[#121f3e] text-[44px] font-black lg:text-left text-center w-full mt-28 mb-4 font-plex">
              Get The Lowest Rates On Life Insurance Today!
            </h2>
            <p class="text-[#6d7d93]  text-[16px] font-medium font-plex lg:text-left text-center mt-2 mb-4 flex flex-row items-center">
              Takes just 60 seconds. Instant Coverage Up to $1,000,000
              <img
                class="auto-[16/16] w-[16px] h-auto object-contain ml-2"
                src="https://s.w.org/images/core/emoji/14.0.0/svg/1f389.svg"
                alt="icon"
              />
            </p>
          </div>
          <div class="flex mt-4 w-full lg:justify-start justify-center"> 
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-4"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
        {LoginAddForm(navigate)}
      </div>
    </div>
  );
};

const FuneralsComponent = () => {
  return (
    <div class="flex w-full xl:w-[1140px] mx-auto mb-20 p-[10px] flex-col">
      <section class="flex w-full mb-20">
        <div class="flex justify-between w-full items-center">
          <h2 class="flex w-full text-[#121f3e] lg:text-[40px] sm:text-[30px] text-[20px] font-bold font-plex text-left">
            We know talking about funerals is
            <br />
            hard, we make it easy.
          </h2>
          <div class="flex lg:justify-start justify-center">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-4"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </section>

      <section class="flex w-full flex-col">
        <div class="flex  w-full justify-between align-middle items-center">
          <div class="items-center justify-center p-[10px]">
            <img
              class="auto-[744/256]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-5-1.jpg"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[524/280]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-8-1-1.jpg"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[672/356]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-9-1.jpg"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[800/204]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-10-1.png"
              alt=""
            />
          </div>
        </div>
        <div class="flex  w-full justify-between align-middle items-center">
          <div class="items-center justify-center p-[10px]">
            <img
              class="auto-[800/254]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-7-1.png"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[448/380]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/Canada_Protection_Plan_Logo-1.jpg"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[788/232]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/2x-Assumption-1.png"
              alt=""
            />
          </div>
          <div class="flex  items-center justify-center p-[10px]">
            <img
              class="auto-[784/192]  sm:w-[110px] md:w-[150px] lg:w-[200px] w-full h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/download-6-1.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
};

const OurClientsComponent = () => {
  const { width } = ScreenSize();
  const swiper = useSwiper();

  return (
    <div class="flex w-full xl:w-[1140px] mx-auto mb-10 p-[10px] flex-col">
      <div class="flex mb-5 w-full">
        <h2 class="flex text-[#121f3e] font-bold font-plex lg:text-[40px] sm:text-[30px] text-[20px] mx-auto">
          Our Clients Know We Care
        </h2>
      </div>
      {/* <button class="custom_next">Slide to the next slide</button> */}
      <div class="flex">
        <Swiper
          slidesPerView={width < 1023 ? 1 : 2}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          loop={true}
          // navigation={true}
          navigation={{
            nextEl: ".custom_next",
            prevEl: ".custom_prev"
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {OurClients.map((item) => {
            return (
              <SwiperSlide>
                <CardView clientInfo={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div class="flex flex-row mx-auto w-[120px] justify-between mt-20">
        <div class="flex w-10 h-10 rounded-full bg-[#e8f3ff] items-center justify-center">
          <button class="custom_prev">
            <FcPrevious class="w-[20px] h-[20px] fill-[#1e86ff]" />
          </button>
        </div>
        <div class="flex w-10 h-10 rounded-full bg-[#e8f3ff] items-center justify-center">
          <button class="custom_next">
            <FcNext class="w-[20px] h-[20px] fill-[#1e86ff]" />
          </button>
        </div>
      </div>     
    </div>
  );
};


const ItemData = props => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  return (
    <div class="flex w-full mb-[25px] flex-col bg-white">
      <button
        {...getToggleProps({
          onClick: () => setExpanded((prevExpanded) => !prevExpanded),
        })}
      >
        <div class="flex w-full py-[40px] px-[15px] flex-row items-center">
          <span class="flex">
          {isExpanded ? <FontAwesomeIcon
            icon={faAngleUp}
            className="w-[16px] h-[16px] "
            color="#121f3e"
            swapOpacity="false"
          /> : 
          <FontAwesomeIcon
            icon={faAngleDown}
            className="w-[16px] h-[16px] "
            color="#121f3e"
            swapOpacity="false"
          />}
          </span>
          <h2 class="flex w-full text-left text-[#121f3e] text-[16px] font-medium font-plex ml-2">
            {props.item.title}
          </h2>
        </div>
      </button>
      
      <div {...getCollapseProps()}>
        <h2  class="flex w-full text-[#6d7d93] text-[14px] font-normal py-[15px] px-[20px] text-left font-plex">
          {props.item.description}
        </h2>
      </div>
    </div>
  )
}
const InsuranceComponent = () => {
  return (
    <section class="bg-[#f8fafb] py-[60px]">
      <div class="flex w-full xl:w-[1140px] mx-auto p-[10px] lg:flex-row flex-col ">
        <div class="flex w-full flex-col">
          <h2 class="flex w-full text-[#121f3e] font-bold font-plex lg:text-[40px] sm:text-[30px] text-[20px] text-left mb-5">
            What makes Hero Life Insurance so different?
          </h2>
          <h2 class="flex w-full text-[#6d7d93] text-[16px] font-normal font-plex text-left mb-5">
            We go the extra mile by giving you the right coverage with the right
            terms, for the right price. We also offer amongst the fastest benefit
            payouts in the industry!{" "}
          </h2>
          <div class="flex mt-4 w-full lg:justify-start justify-center">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full mt-4"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
        <div class="flex w-full p-[10px] flex-col">
          {
            HelpDifficults.map(item => {
              return <ItemData item={item} />
              
            })
          }
        </div>
      </div>
    </section>
    
  );
};

const RequestComponent = (navigate) => {
  return (
    <div class="flex w-full xl:w-[1140px] mx-auto mb-[0px] mt-20 p-[10px] flex-col">
      <div class="flex w-full p-[10px] flex-col mb-10`">
        <h2 class="flex  mx-auto text-[#121f3e] lg:text-[40px] sm:text-[30px] text-[20px] font-bold font-plex text-center mb-5">
          Request a Quote
        </h2>
        <h2 class="flex mx-auto text-[#6d7d93] text-[16px] font-normal font-plex text-center mb-8">
          Please answer this question below.
        </h2>
      </div>
      <QuoteInput onNext={() => navigate("/thank-you")}/>
    </div>
  )
}

const FootComponent = () => {
  return (
    <div class="flex w-full xl:w-[1140px] mx-auto mb-10 mt-[150px] p-[10px] md:flex-row flex-col  justify-between">
      <div class="flex p-[10px] w-full md:justify-start justify-center">
        <img
          src={Logo}
          class="w-[200px] h-auto auto-[800/220] object-contain"
          alt="logo"
        />
      </div>
      <div class="flex p-[10px] md:justify-end justify-center items-center w-full mr-5">
        <h2 class="flex font-plex font-semibold text-[24px] text-[#121f3e] ">
          Protect your Family
        </h2>
      </div>
      <div className="flex p-[10px]  md:justify-start justify-center">
        <button
          class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-plex text-base font-semibold rounded-full"
          // onClick={() => nextButtonClicked()}
        >
          Get a Quote
        </button>
      </div>
    </div>
  )
}

function Home() {
  const navigate = useNavigate();
  return (
    <Header>
      {HomeMainView(navigate)}
      {LifeComponent()}
      {FinancialComponent()}
      {ProtectFamilyComponent()}
      {FuneralsComponent()}
      {OurClientsComponent()}
      {InsuranceComponent()}
      {RequestComponent(navigate)}
      {FootComponent()}
    </Header>
  );
}

export default Home;
