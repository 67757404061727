import React from "react";
import Logo from "../../assets/logo.png";

const TermsComponent = () => {
  return (
    <div class="w-full mx-auto xl:w-[920px]">
      <section class="w-full flex mx-auto py-[50px] items-center">
        <h2 class="text-[#121F3E] md:text-[40px] text-[30px] font-bold font-sans mx-auto">
          Terms Of Service
        </h2>
      </section>
      <section>
        <div class="flex w-full flex-col p-[10px]">
          <h2 class="flex text-[#121F3E] text-[32px] font-bold font-sans mt-2 mb-4">
            Term of Service
          </h2>
          <p class="flex text-[#121F3E] text-[16px] font-normal font-sans mb-[14.4px] text-left">
            Last updated: 04/18/2021
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Introduction
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Welcome to One For All Corporation (“Company”, “we”, “our”, “us”)!
            As you have just clicked our Terms of Service, please pause, grab a
            cup of coffee and carefully read the following pages. It will take
            you approximately 20 minutes. These Terms of Service (“Terms”,
            “Terms of Service”) govern your use of our web pages located at
            https://bankonhero.com and our mobile application Hero (together or
            individually “Service”) operated by One For All Corporation Our
            Privacy Policy also governs your use of our Service and explains how
            we collect, safeguard and disclose information that results from
            your use of our web pages. Please read it here
            https://bankonhero.com/legal. Your agreement with us includes these
            Terms and our Privacy Policy (“Agreements”). You acknowledge that
            you have read and understood Agreements, and agree to be bound of
            them. If you do not agree with (or cannot comply with) Agreements,
            then you may not use the Service, but please let us know by emailing
            at hello@bankonhero.com so we can try to find a solution. These
            Terms apply to all visitors, users and others who wish to access or
            use Service. Thank you for being responsible.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Communications
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            By creating an Account on our Service, you agree to subscribe to
            newsletters, marketing or promotional materials and other
            information we may send. However, you may opt out of receiving any,
            or all, of these communications from us by following the unsubscribe
            link or by emailing at.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Purchases
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you wish to purchase any product or service made available
            through Service (“Purchase”), you may be asked to supply certain
            information relevant to your Purchase including, without limitation,
            your credit card number, the expiration date of your credit card,
            your billing address, and your shipping information.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You represent and warrant that: (i) you have the legal right to use
            any credit card(s) or other payment method(s) in connection with any
            Purchase; and that (ii) the information you supply to us is true,
            correct and complete.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may employ the use of third party services for the purpose of
            facilitating payment and the completion of Purchases. By submitting
            your information, you grant us the right to provide the information
            to these third parties subject to our Privacy Policy.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We reserve the right to refuse or cancel your order at any time for
            reasons including but not limited to: product or service
            availability, errors in the description or price of the product or
            service, error in your order or other reasons.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We reserve the right to refuse or cancel your order if fraud or an
            unauthorized or illegal transaction is suspected.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Contests, Sweepstakes and Promotions
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Any contests, sweepstakes or other promotions (collectively,
            “Promotions”) made available through Service may be governed by
            rules that are separate from these Terms of Service. If you
            participate in any Promotions, please review the applicable rules as
            well as our Privacy Policy. If the rules for a Promotion conflict
            with these Terms of Service, Promotion rules will apply.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Subscriptions
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Some parts of Service are billed on a subscription basis
            (“Subscription(s)”). You will be billed in advance on a recurring
            and periodic basis (“Billing Cycle”). Billing cycles are set either
            on a monthly or annual basis, depending on the type of subscription
            plan you select when purchasing a Subscription.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you
            cancel it or One For All Corporation cancels it. You may cancel your
            Subscription renewal either through your online account management
            page or by contacting One For All Corporation customer support team.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            A valid payment method, including credit card, is required to
            process the payment for your subscription. You shall provide One For
            All Corporation with accurate and complete billing information
            including full name, address, state, zip code, telephone number, and
            a valid payment method information. By submitting such payment
            information, you automatically authorize One For All Corporation to
            charge all Subscription fees incurred through your account to any
            such payment instruments.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Should automatic billing fail to occur for any reason, One For All
            Corporation will issue an electronic invoice indicating that you
            must proceed manually, within a certain deadline date, with the full
            payment corresponding to the billing period as indicated on the
            invoice.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Free Trial
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation may, at its sole discretion, offer a
            Subscription with a free trial for a limited period of time (“Free
            Trial”).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may be required to enter your billing information in order to
            sign up for Free Trial.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you do enter your billing information when signing up for Free
            Trial, you will not be charged by One For All Corporation until Free
            Trial has expired. On the last day of Free Trial period, unless you
            cancelled your Subscription, you will be automatically charged the
            applicable Subscription fees for the type of Subscription you have
            selected.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            At any time and without notice, One For All Corporation reserves the
            right to (i) modify Terms of Service of Free Trial offer, or (ii)
            cancel such Free Trial offer.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Fee Changes
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation, in its sole discretion and at any time, may
            modify Subscription fees for the Subscriptions. Any Subscription fee
            change will become effective at the end of the then-current Billing
            Cycle.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation will provide you with a reasonable prior
            notice of any change in Subscription fees to give you an opportunity
            to terminate your Subscription before such change becomes effective.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your continued use of Service after Subscription fee change comes
            into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Refunds
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We issue refunds for Contracts within seven (7) days of the original
            purchase of the Contract.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Content
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Content found on or through this Service are the property of One For
            All Corporation or used with permission. You may not distribute,
            modify, transmit, reuse, download, repost, copy, or use said
            Content, whether in whole or in part, for commercial purposes or for
            personal gain, without express advance written permission from us.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Prohibited Uses
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:``
          </p>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                In any way that violates any applicable national or
                international law or regulation.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content or otherwise.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail”, “chain letter,”
                “spam,” or any other similar solicitation.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                To impersonate or attempt to impersonate Company, a Company
                employee, another user, or any other person or entity.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                In any way that infringes upon the rights of others, or in any
                way is illegal, threatening, fraudulent, or harmful, or in
                connection with any unlawful, illegal, fraudulent, or harmful
                purpose or activity.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of Service, or which, as determined by
                us, may harm or offend Company or users of Service or expose
                them to liability.
              </p>
            </li>
          </ul>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Additionally, you agree not to:
          </p>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Use any device, software, or routine that interferes with the proper working of Service.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Take any action that may damage or falsify Company rating.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Otherwise attempt to interfere with the proper working of Service.
              </p>
            </li>
          </ul>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
          Analytics
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
          We may use third-party Service Providers to monitor and analyze the use of our Service.
          </p>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Google Analytics
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              We also encourage you to review the Google’s policy for safeguarding your data: https://support.google.com/analytics/answer/6004245.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Piwik / Matomo
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Piwik or Matomo is a web analytics service. You can visit their Privacy Policy page here: https://matomo.org/privacy-policy
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Cloudflare analytics
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Cloudflare analytics is a web analytics service operated by Cloudflare Inc. Read the Privacy Policy here: https://www.cloudflare.com/privacypolicy/
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Mixpanel
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              Mixpanel is provided by Mixpanel Inc.
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              You can prevent Mixpanel from using your information for analytics purposes by opting-out. To opt-out of Mixpanel service, please visit this page: https://mixpanel.com/optout/
              </p>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              For more information on what type of information Mixpanel collects, please visit the Terms of Use page of Mixpanel: https://mixpanel.com/terms/
              </p>
            </li>
          </ul>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Content
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Content found on or through this Service are the property of One For
            All Corporation or used with permission. You may not distribute,
            modify, transmit, reuse, download, repost, copy, or use said
            Content, whether in whole or in part, for commercial purposes or for
            personal gain, without express advance written permission from us.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            No Use By Minors
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Service is intended only for access and use by individuals at least
            eighteen (18) years old. By accessing or using any of Company, you
            warrant and represent that you are at least eighteen (18) years of
            age and with the full authority, right, and capacity to enter into
            this agreement and abide by all of the terms and conditions of
            Terms. If you are not at least eighteen (18) years old, you are
            prohibited from both the access and usage of Service.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Accounts
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders in our sole discretion.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Intellectual Property
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of One For All Corporation and its licensors. Service is
            protected by copyright, trademark, and other laws of the United
            States and foreign countries. Our trademarks and trade dress may not
            be used in connection with any product or service without the prior
            written consent of One For All Corporation.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Copyright Policy
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on Service
            infringes on the copyright or other intellectual property rights
            (“Infringement”) of any person or entity.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to legal@bankonhero.com, with the subject line: “Copyright
            Infringement” and include in your claim a detailed description of
            the alleged Infringement as detailed below, under “DMCA Notice and
            Procedure for Copyright Infringement Claims”
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may be held accountable for damages (including costs and
            attorneys’ fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            DMCA Notice and Procedure for Copyright Infringement Claims
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            DMCA Notice and Procedure for Copyright Infringement Claims
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </p>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright’s interest;
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              identification of the URL or other specific location on Service where the material that you claim is infringing is located;
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              your address, telephone number, and email address;
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.
              </p>
            </li>
          </ul>
          <ul class="list-disc pl-10">
            <li>
              <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
              You can contact our Copyright Agent via email at legal@bankonhero.com
              </p>
            </li>
          </ul>

          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Error Reporting and Feedback
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You may provide us either directly at hello@bankonhero.com or via
            third party sites and tools with information and feedback concerning
            errors, suggestions for improvements, ideas, problems, complaints,
            and other matters related to our Service (“Feedback”). You
            acknowledge and agree that: (i) you shall not retain, acquire or
            assert any intellectual property right or other right, title or
            interest in or to the Feedback; (ii) Company may have development
            ideas similar to the Feedback; (iii) Feedback does not contain
            confidential information or proprietary information from you or any
            third party; and (iv) Company is not under any obligation of
            confidentiality with respect to the Feedback. In the event the
            transfer of the ownership to the Feedback is not possible due to
            applicable mandatory laws, you grant Company and its affiliates an
            exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            The third party sites and tools mentioned above include the
            following:
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Bugsnag
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Bugsnag is a platform for monitoring and logging stability of
            applications provided by Bugsnag Inc. Please read their Privacy
            Policy here: https://docs.bugsnag.com/legal/privacy-policy/
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Links To Other Web Sites
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by One For All Corporation
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation has no control over, and assumes no
            responsibility for the content, privacy policies, or practices of
            any third party web sites or services. We do not warrant the
            offerings of any of these entities/individuals or their websites.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            YOU ACKNOWLEDGE AND AGREE THAT One For All Corporation SHALL NOT BE
            RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR
            LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF
            OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR
            THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Disclaimer Of Warranty
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
            THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Limitation Of Liability
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
            COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
            THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
            PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Termination
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may terminate or suspend your account and bar access to Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of Terms.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you wish to terminate your account, you may simply discontinue
            using Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Governing Law
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            These Terms shall be governed and construed in accordance with the
            laws of Province of Canada without regard to its conflict of law
            provisions.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Changes To Service
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or
            the entire Service, to users, including registered users.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Amendments To Terms
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.By continuing to access or use
            our Service after any revisions become effective, you agree to be
            bound by the revised terms. If you do not agree to the new terms,
            you are no longer authorized to use Service.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Waiver And Severability
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            No waiver by Company of any term or condition set forth in Terms
            shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of Company to assert a right or provision under Terms shall
            not constitute a waiver of such right or provision.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If any provision of Terms is held by a court or other tribunal of
            competent jurisdiction to be invalid, illegal or unenforceable for
            any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will
            continue in full force and effect.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Acknowledgement
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Contact Us
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Please send your feedback, comments, requests for technical support:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[40px]">
            By email: hello@bankonhero.com.
            <br />
            By mail: 140 Yonge Street Toronto, ON M5C 1X6.
          </p>
        </div>
      </section>
    </div>
  );
};

function TermsService() {
  return (
    <div class=" h-screen">
      <nav class=" flex items-center justify-between px-2 py-3 mb-1 ">
        <div class="flex xl:w-[1140px] 2xl:w-[1140px] w-full mx-auto justify-between">
          <div class="w-full relative flex justify-start  lg:static lg:justify-start">
            <img
              src={Logo}
              class="w-[200px] h-auto auto-[300/82]"
              alt="logo"
            />
          </div>
          <div class="flex justify-center">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-bold font-sans rounded-full"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </nav>
      <div>{TermsComponent()}</div>
    </div>
  );
}

export default TermsService;
