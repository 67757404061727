import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { RiCloseCircleFill } from "react-icons/ri";

function AlertMessage(props) {
  const { icon, message } = props;
  return (
    <div
      class={`flex bg-white border-[1.5px] border-[#c02b0a] rounded-md my-2 items-center ${
        icon ? "pl-4" : "pl-6"
      } ${icon ? "p-4" : "p-[13px]"}
    `}
    >
      {icon && (
        <RiCloseCircleFill class="fill-[#c02b0a] flex w-5 h-5 mr-2" />
      )}
      <h2
        class={`text-[#c02b0a] font-sans text-left flex-col  ${
          icon ? "font-medium" : "font-normal"
        } ${icon ? "text-[13.2px]" : "text-[15px]"}
      `}
      >
        {message}
      </h2>
    </div>
  );
}

export default AlertMessage;
