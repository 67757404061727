import React from 'react';

function SelectOption(props) {
  return (
    <div>
      <select
        aria-flowto="bottom"
        class="flex appearance-none text-base font-bold w-full border bg-white h-14 focus:border-2 border-gray-200 text-[#96A0B5] py-3 pl-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
        id="grid-state"
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      >
        {props.values.map((data) => {
          return <option>{data}</option>
        })}
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}

export default SelectOption;
