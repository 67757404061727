import React, { useState, useMemo, useEffect } from "react";
import InputField from "../../components/inputField";
import AlertMessage from "../../components/alertMessage";
import SelectOption from "../../components/selectOption";
import countryList from "react-select-country-list";
import InputMask from "react-input-mask";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { API_END_POINTS } from "../../utils";
import axios from "axios";

const addressCompleteKey = "BG74-PM22-JZ16-KR28";

function StepFirst({ nextStep }) {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    country: "Canada",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    streetAddress: false,
    city: false,
    province: false,
    postalCode: false,
    phoneNumber: false,
    email: false,
  });

  const [isSubmit, setIsSubmit] = useState(false);

  const options = useMemo(() => countryList().getData(), []);
  const countries = options.map((itme) => itme.label);
  const [address, setAddress] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    setFormValues({ ...formValues, [name]: value });
  };

  const stateNameToAbbreviation = (name) => {
    var states = [
      ["Alberta", "AB"],
      ["British Columbia", "BC"],
      ["Manitoba", "MB"],
      ["New Brunswick", "NB"],
      ["Newfoundland", "NF"],
      ["Northwest Territory", "NT"],
      ["Nova Scotia", "NS"],
      ["Nunavut", "NU"],
      ["Ontario", "ON"],
      ["Prince Edward Island", "PE"],
      ["Quebec", "QC"],
      ["Saskatchewan", "SK"],
      ["Yukon", "YT"],
    ];

    let a = name
      .trim()
      .replace(/[^\w ]/g, "")
      .toUpperCase();

    for (var i = 0; i < states.length; i++) {
      if (states[i][1] === a) {
        return states[i][0];
      }
    }
  };

  useEffect(() => {
    if (isSubmit) {
      if (
        !formErrors.firstName &&
        !formErrors.lastName &&
        !formErrors.streetAddress &&
        !formErrors.city &&
        !formErrors.province &&
        !formErrors.postalCode &&
        !formErrors.phoneNumber &&
        !formErrors.email
      ) {
        nextStep({ step: 1, formData: formValues });
      }
    }
    setIsSubmit(false);
  }, [isSubmit]);

  const nextButtonClicked = () => {
    if (formValues.firstName === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        firstName: true,
      }));
    }
    if (formValues.lastName === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        lastName: true,
      }));
    }
    if (formValues.streetAddress === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        streetAddress: true,
      }));
    }
    if (formValues.city === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        city: true,
      }));
    }
    if (formValues.province === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        province: true,
      }));
    }
    if (formValues.postalCode === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        postalCode: true,
      }));
    }
    if (formValues.phoneNumber === "" || formValues.phoneNumber.replace(/-|_/g, "").length !== 13) {
      setFormErrors((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }));
    }
    if (formValues.email === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
    }
    setTimeout(() => {
      setIsSubmit(true);
    }, 300);
  };

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
      return false;
    }
    return true;
  };

  return (
    <div class="mx-auto mb-8">
      <div class="w-full bg-blue-600 items-center justify-center">
        <p class="text-white font-semibold text-3xl font-sans items-center p-6">
          Accidental Death & Dismemberment Insurance
        </p>
      </div>
      <div class="bg-white w-full xl:w-[1140px] mx-auto mt-20 flex lg:flex-row flex-col">
        <div class="w-full lg:w-[604px] p-8 rounded-xl border">
          {formErrors.firstName &&
          formErrors.lastName &&
          formErrors.streetAddress &&
          formErrors.city &&
          formErrors.province &&
          formErrors.postalCode &&
          formErrors.phoneNumber &&
          formErrors.email ? (
            <AlertMessage
              icon={true}
              message={
                "There was a problem with your submission. Please review the fields below."
              }
            />
          ) : null}

          <div class="w-full">
            <p class="text-[#767676] text-[13px] text-left font-normal flex items-center">
              Step 1 of 4
            </p>
          </div>
          <div class="w-full my-6">
            <p class="text-[#121f3e] text-[22px] font-semibold font-sans text-left flex items-center mt-2 mb-4">
              Great! It looks like you qualify based on provided information.
              Now let’s check your residency status.
            </p>
          </div>

          <div class="w-full mt-8">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Name
            </label>
            <div class="flex flex-wrap mb-2">
              <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
                <InputField
                  type="text"
                  placeholder="First"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </div>
              <div class="w-full md:w-1/2 md:pl-1.5">
                <InputField
                  type="text"
                  placeholder="Last"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            {formErrors.firstName || formErrors.lastName ? (
              <AlertMessage icon={false} message={"This filed is required"} />
            ) : null}
          </div>

          <div class="w-full mt-8">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Address
            </label>

            <div class=" mb-2 w-full">
              <div class="w-full mb-3">
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyDTdlq8hNy1hy8NjlNGU-QD4-FgVvjH5WI"
                  // apiKey="AIzaSyBNel2zP5DFtvXjtbVD3cx0ntWzLia8d1U"
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["ca"],
                    },
                  }}
                  class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
                  debounce={500}
                  selectProps={{
                    // class:"flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500",
                    styles: {
                      container: (provided) => ({
                        ...provided,
                        height: 54,
                        borderRadius: 18,
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        width: 0,
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        width: 0,
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: 54,
                        borderRadius: 18,
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#96a0b5",
                        fontWeight: "bold",
                      }),
                      option: (provided) => ({
                        ...provided,
                        color: "#7a7a7a",
                        textAlign: "left",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: "#7a7a7a",
                        fontSize: 16,
                        fontWeight: 900,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#96a0b5",
                        fontSize: 16,
                        fontWeight: 900,
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        backgroundColor: "yellow",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 8,
                        borderColor: "rgb(229 231 235)",
                      }),
                    },
                    placeholder: "Street Address",
                    onChange: async (value) => {
                      var params = API_END_POINTS.GET_ADDRESS;
                      params += "?Key=" + encodeURIComponent(addressCompleteKey);
                      params += "&SearchTerm=" + encodeURIComponent(value.value.terms[0].value + " " + value.value.terms[1].value);
                      params += "&Country=" + encodeURIComponent("CAN");
                      params +=
                        "&LanguagePreference`=" + encodeURIComponent("en");

                      var header = {
                        "Content-type": "application/x-www-form-urlencoded",
                      };

                      axios.post(params, header).then((res) => {
                        const words = res.data.Items[0].Description.split(",");
                        setFormValues({
                          ...formValues,
                          city: value.value.terms[2].value,
                          streetAddress: value.value.description,
                          province: stateNameToAbbreviation(
                            value.value.terms[3].value
                          ),
                          postalCode: words[2],
                        });
                        setFormErrors({
                          streetAddress: false,
                          city: false,
                          province: false,
                          postalCode: false,
                        });
                      });
                    },
                  }}
                />
              </div>

              <div class="w-full">
                <InputField
                  type="text"
                  placeholder="No. & Street"
                  name="state"
                />
              </div>
            </div>

            <div class="flex flex-wrap mb-2">
              <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
                <InputField
                  type="text"
                  placeholder="City"
                  name="city"
                  value={formValues.city}
                  onChange={handleChange}
                />
              </div>
              <div class="w-full md:w-1/2 md:pl-1.5">
                <InputField
                  type="text"
                  placeholder="Province"
                  name="province"
                  value={formValues.province}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div class="flex flex-wrap mb-2">
              <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
                <InputField
                  placeholder="Postal Code"
                  name="postal"
                  value={formValues.postalCode}
                  onChange={handleChange}
                />
              </div>
              <div class="w-full md:w-1/2 md:pl-1.5">
                <SelectOption
                  value={formValues.country}
                  onChange={handleChange}
                  values={countries}
                  name="country"
                />
              </div>
            </div>
            {formErrors.streetAddress ||
            formErrors.city ||
            formErrors.province ||
            formErrors.postalCode ? (
              <AlertMessage
                icon={false}
                message={`This filed is required. Please complete the following fields: ${
                  formErrors.streetAddress ? "Street Address" + "," : ""
                } ${formErrors.city ? "City" + "," : ""} ${
                  formErrors.province ? "State / Province" : ""
                } ${formErrors.postalCode ? "ZIP / Postal Code" : ""}`}
              />
            ) : null}
          </div>

          <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full md:w-1/2 md:pr-1.5 mb-6 md:mb-0">
              <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
                Phone
              </label>
              <InputMask
                class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
                mask="(999) 999-9999"
                // maskChar = {null}
                name="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handleChange}
              />
              {formErrors.phoneNumber && (
                <AlertMessage
                  icon={false}
                  message={"Phone format: (###) ###-####"}
                />
              )}
            </div>
            <div class="w-full md:w-1/2 md:pl-1.5">
              <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
                Email
              </label>
              <InputField
                type="text"
                placeholder=""
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
              {formErrors.email ? (
                <AlertMessage icon={false} message={"This filed is required"} />
              ) : null}
              {!emailValidation(formValues.email) &&
              !formErrors.email &&
              formValues.email !== "" ? (
                <AlertMessage
                  icon={false}
                  message={
                    "The email address entered is invalid, please check the formatting (e.g. email@domain.com)."
                  }
                />
              ) : null}
            </div>
          </div>

          <div class="flex mx-auto mb-14">
            <button
              class="bg-blue-500 mx-auto hover:bg-blue-700 text-white text-base font-medium font-sans py-3 px-4 h-14 rounded-full w-full md:w-1/2"
              type="submit"
              disabled={false}
              onClick={() => nextButtonClicked()}
            >
              Next
            </button>
          </div>
        </div>
        <div class="w-full lg:w-[536px]  p-8">
          <div class="w-full mb-5">
            <h2 class="text-[#121f3e] text-[44px] font-bold font-sans text-center lg:text-left">
              Why do we ask this?
            </h2>
          </div>
          <div class="w-full mb-5">
            <p class="text-[#6d7d93] text-base font-medium font-sans text-left">
              Your residency determines your eligibility for this plan. In
              addition, accurate information is needed should you decide to
              proceed with the purchase of this plan. Please note there are
              absolutely no obligations at this stage.
            </p>
          </div>
          <div class="flex justify-center lg:justify-start">
            <img
              class="auto-[180/87] w-[180px] h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/bbb_new_ico-2.jpg"
              alt=""
            />
            <img
              class="auto-[96/105] w-[96px] h-auto object-contain ml-8"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/money_back_ico.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepFirst;
