import React from 'react';

function CircleNumber(props) {
  return (
      <span class='text-[#1e86ff] font-bold text-base font-plex bg-blue-100  rounded-full items-center justify-center flex h-8 w-8'>
        {props.stepNumber}
      </span>
  );
}

export default CircleNumber;
