import React, { useState } from "react";
import SelectOption from "../selectOption";
import CircleNumber from "../circleNumber";
import { Dates, OurClients, HelpDifficults } from "../../utils";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import InputField from "../inputField";
import SelectButton from "../selectButton";
import { useNavigate } from "react-router-dom";

function QuoteInput(props) {
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    email: "",
    isSmoking: null,
    isMan: null,
  });

  const [rating, setRating] = useState(0);
  const [hovered, setHovered] = useState(0);

  const ratingValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [giveRatingValue, setGiveRatingValue] = useState(null);

  const nextStep = () => {
    // const navigate = useNavigate();
    if (step === 7) {
      props.onNext();
    }
    if (step < 7) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const smokingYes = () => {
    setFormValues({
      ...formValues,
      isSmoking: "Yes",
    });
  };

  const smokingNo = () => {
    setFormValues({
      ...formValues,
      isSmoking: "No",
    });
  };

  const manYes = () => {
    setFormValues({
      ...formValues,
      isMan: "Yes",
    });
  };

  const manNo = () => {
    setFormValues({
      ...formValues,
      isMan: "No",
    });
  };

  function MouseEnter(event) {
    event.target.style.background = "#1e86ff";
  }

  function hoverRating(rating) {
    setHovered(rating);
  }

  function changeRating(newRating) {
    setRating(newRating);
  }

  const selectedIcon = (rating) => {
    return (
      <div class="bg-[url(https://herolifeinsurance.ca/wp-content/uploads/2021/12/Vector-1.png)]  bg-no-repeat bg-center t-r h-[33px] w-[36px] cursor-pointer bg-blend-overlay">
        <span class=" text-[12px] text-white font-plex font-semibold">
          {rating}
        </span>
      </div>
    );
  };

  const deselectedIcon = (rating) => {
    return (
      <div class="bg-[url(https://herolifeinsurance.ca/wp-content/uploads/2021/12/Vector-1.png)]  bg-no-repeat bg-center t-r h-[33px] w-[36px] cursor-pointer bg-blend-overlay bg-[#ffffffe0]">
        <span class=" text-[12px] text-[#121f3e] font-plex font-semibold mt-1">
          {rating}
        </span>
      </div>
    );
  };

  const heartRatingView = (star) => {
    return (
      <span
        className="full w-full h-full"
        onClick={() => changeRating(star)}
        onMouseEnter={() => hoverRating(star)}
        onMouseLeave={() => hoverRating(0)}
      >
        {rating < star
          ? hovered < star
            ? deselectedIcon(star)
            : selectedIcon(star)
          : selectedIcon(star)}
      </span>
    );
  };

  const stepComponent = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="1" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left">
                What type of coverage do you want?
              </p>
            </div>
            <div class="flex w-full flex-col mt-[10px]">
              <div class="flex w-full justify-between">
                <div class="w-full">
                  <SelectOption
                    // value={}
                    // onChange={handleChange}
                    values={Dates.coverages}
                    name="day"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="2" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left">
                Full Name
              </p>
            </div>
            <div class="flex flex-wrap mt-3">
              <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
                <InputField
                  type="text"
                  required="true"
                  placeholder="First"
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleChange}
                />
              </div>
              <div class="w-full md:w-1/2 md:pl-1.5">
                <InputField
                  type="text"
                  placeholder="Last"
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div class="w-full">
            <div class="flex flex-row">
              <CircleNumber stepNumber="3" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left">
                Mobile Phone
              </p>
            </div>
            <div class="flex flex-wrap mt-3">
              <div class="w-full w-full">
                <InputField
                  type="text"
                  placeholder="Mobile Number"
                  name="mobilePhone"
                  value={formValues.mobilePhone}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="4" />
              <p class="text-2xl text-[#121f3e] font-plex font-semibold ml-4 text-left">
                Email
              </p>
            </div>
            <div class="flex flex-wrap mt-3">
              <div class="w-full w-full">
                <InputField
                  type="text"
                  placeholder="ex: yourname@gmail.com"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="5" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left">
                Do you smoke?
              </p>
            </div>
            <div class="flex flex-wrap w-full mt-6">
              <div class="flex w-full justify-between ">
                <div class="w-full">
                  <SelectButton
                    name="Yes"
                    selected={formValues.isSmoking === "Yes" ? true : false}
                    onChange={smokingYes}
                    type={"button"}
                    disabled={formValues.isSmoking === "Yes"}
                  />
                </div>
                <div class="w-full ml-4">
                  <SelectButton
                    name="No"
                    selected={formValues.isSmoking === "No" ? true : false}
                    onChange={smokingNo}
                    type={"button"}
                    // disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="6" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left">
                Are you man or woman?
              </p>
            </div>
            <div class="flex flex-wrap w-full mt-6">
              <div class="flex w-full justify-between ">
                <div class="w-full">
                  <SelectButton
                    name="Yes"
                    selected={formValues.isMan === "Yes" ? true : false}
                    onChange={manYes}
                    type={"button"}
                    disabled={formValues.isMan === "Yes"}
                  />
                </div>
                <div class="w-full ml-4">
                  <SelectButton
                    name="No"
                    selected={formValues.isMan === "No" ? true : false}
                    onChange={manNo}
                    type={"button"}
                    // disabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <div>
            <div class="flex flex-row">
              <CircleNumber stepNumber="7" />
              <p class="text-2xl font-semibold text-[#121f3e] font-plex ml-4 text-left w-full">
                How committed are you to insuring and protecting your family?
              </p>
            </div>
            <div class="flex flex-wrap w-full mt-6">
              <div class="flex w-full justify-between ">
                {ratingValue.map((item) => heartRatingView(item))}
              </div>
            </div>
          </div>
        );
      default:
        console.log("Init Step");
    }
  };

  return (
    <div class="flex mx-auto w-full">
      <div class="flex w-[578px] mx-auto my-auto py-[50px] px-[30px] border border-[#e5ebf0] flex-col bg-white rounded-lg shadow-[0_14px_61px_-8px_rgba(0,0,0,0.06)]">
        {stepComponent()}
        <div
          class={`flex w-full mt-2 flex-row ${
            step === 1 ? "justify-end" : "justify-between"
          }`}
        >
          {step !== 1 && (
            <button
              class="bg-white hover:bg-blue-500 text-blue-500 hover:text-white font-normal font-plex px-[25px] py-[15px] rounded-full flex mt-5 mb-5 border-blue-500 border"
              onClick={prevStep}
            >
              <GoArrowLeft class="m-1  hover:fill-white" />
              Previous
            </button>
          )}
          <button
            class={`bg-blue-500 hover:bg-blue-700 text-white text-base font-normal font-sans pl-[35px]  ${
              step === 7 ? "pr-[35px]" : "pr-[25px]"
            } {} pr-[25px]  py-[15px] rounded-full flex mt-5 mb-5`}
            onClick={nextStep}
          >
            {step === 7 ? "Submit" : "Next"}
            {step !== 7 && <GoArrowRight class="m-1 fill-white" />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuoteInput;
