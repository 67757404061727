import React from "react";

function StepThanks() {
  return (
    <div class="mx-auto mb-8">
      <div class="w-full bg-blue-600 items-center justify-center">
        <p class="text-white font-semibold text-3xl font-sans items-center p-6">
          Accidental Death & Dismemberment Insurance
        </p>
      </div>
      <div class="bg-white w-full xl:w-[1140px] mx-auto mt-20 flex lg:flex-row flex-col">
        <div class="w-full lg:w-[604px] h-[86px] p-8 rounded-xl border box-border">
          <p class="text-[#7a7a7a] text-base font-normal text-left font-sans flex items-center">
            Thanks for contacting us! We will get in touch with you shortly.
          </p>
        </div>
        <div class="w-full lg:w-[536px]  p-8">
          <div class="w-full mb-5">
            <h2 class="text-[#121f3e] text-[44px] font-bold font-sans text-center lg:text-left">
              Why do we ask this?
            </h2>
          </div>
          <div class="w-full mb-5">
            <p class="text-[#6d7d93] text-base font-medium font-sans text-left">
              Your residency determines your eligibility for this plan. In
              addition, accurate information is needed should you decide to
              proceed with the purchase of this plan. Please note there are
              absolutely no obligations at this stage.
            </p>
          </div>
          <div class="flex justify-center lg:justify-start">
            <img
              class="auto-[180/87] w-[180px] h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/bbb_new_ico-2.jpg"
              alt=""
            />
            <img
              class="auto-[96/105] w-[96px] h-auto object-contain ml-8"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/money_back_ico.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThanks;
