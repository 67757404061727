import React, { useState } from "react";
import AlertMessage from "../../components/alertMessage";
import BeneficiarySection from "../../components/beneficiarySection";

function StepThird({ nextStep, prevStep }) {
  const [beneficiaryArray, setBeneficiaryArray] = useState([
    {
      firstName: "",
      lastName: "",
      day: "",
      month: "",
      year: "",
      beneficiary: "",
    },
  ]);
  const [showError, setShowError] = useState(false);

  const appenform = (val) => {
    const { idx, miniForm } = val;
    setBeneficiaryArray(
      beneficiaryArray.map((el, key) =>
        key === idx ? Object.assign({}, el, miniForm) : el
      )
    );
  };

  const addBeneficiary = (val, index) => {
    const newTodos = [...beneficiaryArray];
    if (newTodos.length < 6) {
      newTodos.push({
        firstName: "",
        lastName: "",
        relationship: "Mother",
        beneficiary: "",
        day: "",
        month: "",
        year: "",
      });
      setBeneficiaryArray(newTodos);
    }
  };

  const removeBeneficiary = (val, index) => {
    console.log("Remove:-", index);
    if (beneficiaryArray.length > 1) {
      const newTodos = beneficiaryArray.filter((element, key) => key !== index);
      setBeneficiaryArray(newTodos);
    }
  };

  const nextButtonClicked = () => {
    const filtered = beneficiaryArray.filter((obj) => {
      return (
        obj.firstName === "" ||
        obj.lastName === "" ||
        obj.day === "" ||
        obj.month === "" ||
        obj.year === ""
      );
    });
    if (filtered.length === 0) {
      nextStep({ step: 3, formData: beneficiaryArray });
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const previousButtonClicked = () => {
    setShowError(false);
    prevStep();
  };

  return (
    <div class="mx-auto mb-8">
      <div class="w-full bg-blue-600 items-center justify-center">
        <p class="text-white font-semibold text-3xl font-sans items-center p-6">
          Accidental Death & Dismemberment Insurance
        </p>
      </div>
      <div class="bg-white w-full xl:w-[1140px] mx-auto mt-20 flex lg:flex-row flex-col">
        <div class="w-full lg:w-[604px] p-8 rounded-xl border">
          {showError && (
            <AlertMessage
              icon={true}
              message={
                "There was a problem with your submission. Please review the fields below."
              }
            />
          )}
          <div class="w-full">
            <p class="text-[#767676] text-[13px] text-left font-normal  flex items-center">
              Step 3 of 4
            </p>
          </div>
          <div class="w-full my-6">
            <p class="text-[#121f3e] text-[22px] font-semibold font-sans text-left flex items-center mt-2 mb-4">
              Your quote has been secured.
            </p>
          </div>
          <div class="w-full my-8">
            <p class="text-[#121f3e] text-[18px] font-semibold font-sans text-left flex items-center mt-2 mb-4">
              Beneficiary
            </p>
          </div>

          <div class="w-full mt-8 mb-8">
            <div class="w-full mt-8">
              {beneficiaryArray.map((val, idx) => (
                <BeneficiarySection
                  idx={idx}
                  formValue={val}
                  addBeneficiary={() => addBeneficiary(val, idx)}
                  removeBeneficiary={() => removeBeneficiary(val, idx)}
                  appenform={appenform}
                  showError={showError}
                />
              ))}
            </div>
          </div>

          <div class="flex my-auto mt-4 md:justify-between flex-col md:flex-row">
            <button
              class="bg-white h-14 w-full md:w-1/4 text-[#1e86ff] font-bold font-sans rounded-full border-2 border-[#1e86ff]"
              onClick={() => previousButtonClicked()}
            >
              Previous
            </button>
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-full md:w-1/4 text-white font-bold font-sans rounded-full mt-4 md:mt-0"
              onClick={() => nextButtonClicked()}
            >
              Next
            </button>
          </div>
        </div>
        <div class="w-full lg:w-[536px]  p-8">
          <div class="w-full mb-5">
            <h2 class="text-[#121f3e] text-[44px] font-bold font-sans text-center lg:text-left">
              Why do we ask this?
            </h2>
          </div>
          <div class="w-full mb-5">
            <p class="text-[#6d7d93] text-base font-medium font-sans text-left">
              Your residency determines your eligibility for this plan. In
              addition, accurate information is needed should you decide to
              proceed with the purchase of this plan. Please note there are
              absolutely no obligations at this stage.1
            </p>
          </div>
          <div class="flex justify-center lg:justify-start">
            <img
              class="auto-[180/87] w-[180px] h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/bbb_new_ico-2.jpg"
              alt=""
            />
            <img
              class="auto-[96/105] w-[96px] h-auto object-contain ml-8"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/money_back_ico.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThird;
