import React, { useState, useEffect } from "react";
import SelectOption from "../../components/selectOption";
import { RelationshipData } from "../../utils";
import SelectButton from "../../components/selectButton";
import AlertMessage from "../../components/alertMessage";
import { Dates } from "../../utils";
function BeneficiarySection({
  idx,
  formValue,
  addBeneficiary,
  removeBeneficiary,
  appenform,
  showError,
}) {
  const [miniForm, setMiniForm] = useState({
    firstName: formValue.firstName,
    lastName: formValue.lastName,
    relationship: formValue.relationship,
    day: formValue.day,
    month: formValue.month,
    year: formValue.year,
  });
  const [beneficiaryAvailable, setBeneficiaryAvailable] = useState(
    formValue.beneficiary
  );
  const [pastYears, setPastYears] = useState([]);

  useEffect(() => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(100), (val, index) => year - index);
    setPastYears(["Year", ...years]);
  }, []);

  useEffect(() => {
    appenform({ idx, miniForm });
  }, [miniForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMiniForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const add = () => {
    setBeneficiaryAvailable("Yes");
    addBeneficiary();
  };

  const remove = () => {
    setBeneficiaryAvailable("No");
    removeBeneficiary();
  };

  return (
    <div>
      <div class="w-full mt-8">
        <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
          Name
        </label>
        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              placeholder="First"
              name="firstName"
              value={miniForm.firstName}
              onChange={handleChange}
            />
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5">
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              placeholder="Last"
              name="lastName"
              value={miniForm.lastName}
              onChange={handleChange}
            />
          </div>
        </div>
        {showError &&
          (miniForm.firstName === "" || miniForm.lastName === "") && (
            <AlertMessage icon={false} message={"This filed is required"} />
          )}
      </div>
      <div class="w-full mt-8">
        <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
          Relationship
        </label>
        <div class="flex flex-wrap mb-2">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0">
            <SelectOption
              value={miniForm.relationship}
              onChange={handleChange}
              values={RelationshipData}
              name="relationship"
            />
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col mt-6">
        <div class="w-full flex mb-2">
          <p class="flex text-sm items-center text-[#121f3e] text-sm font-medium font-sans">
            Date of Birth
          </p>
        </div>
        <div class="flex w-full justify-between">
          <div class="w-full">
            <SelectOption
              value={miniForm.day}
              onChange={handleChange}
              values={Dates.day}
              name="day"
            />
          </div>
          <div class="w-full px-3">
            <SelectOption
              value={miniForm.month}
              onChange={handleChange}
              values={Dates.month}
              name="month"
            />
          </div>
          <div class="w-full">
            <SelectOption
              value={miniForm.year}
              onChange={handleChange}
              values={pastYears}
              name="year"
            />
          </div>
        </div>
      </div>
      {showError &&
        (miniForm.day === "" ||
          miniForm.month === "" ||
          miniForm.year === "" ||
          miniForm.day === "Day" ||
          miniForm.month === "Month" ||
          miniForm.year === "Year") && (
          <AlertMessage icon={false} message={"This filed is required"} />
        )}
      <div class="w-full mt-8">
        <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
          Do you want to add more beneficiary
        </label>
        <div class="flex flex-wrap w-full">
          <div class="flex w-full justify-between ">
            <div class="w-full">
              <SelectButton
                name="Yes"
                selected={beneficiaryAvailable === "Yes" ? true : false}
                onChange={add}
                type={"button"}
                disabled={beneficiaryAvailable === "Yes"}
              />
            </div>
            <div class="w-full ml-4">
              <SelectButton
                name="No"
                selected={beneficiaryAvailable === "No" ? true : false}
                onChange={remove}
                type={"button"}
                // disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeneficiarySection;
