import React from "react";

function CardView(props) {
  const { name, profile, avatar } = props.clientInfo;
  return (
    <div class="flex w-full border rounded-2xl border-gray-200 p-[20px] mt-6 flex-col mr-[10px] min-h-[250px] justify-between">
      <div class="flex text-[#6d7d93] font-normal text-[16px] italic font-sans text-left">
        "{profile}"
      </div>
      <div class="flex flex-row items-center mt-6">
        <img
          class="w-[50px] h-[50px] object-contain rounded-full"
          src={avatar}
          alt=""
        />
        <span class="text-[#121f3e] text-[14px] font-medium font-sans ml-[20px]">
          {name}
        </span>
      </div>
    </div>
  );
}

export default CardView;
