import React from "react";
import Logo from "../../assets/logo.png";

const PrivacyMainView = () => {
  return (
    <div class="w-full mx-auto xl:w-[920px]">
      <section class="w-full flex mx-auto py-[50px] items-center">
        <h2 class="text-[#121F3E] md:text-[40px] text-[30px] font-bold font-sans mx-auto">
          Privacy Policy
        </h2>
      </section>
      <section>
        <div class="flex w-full flex-col p-[10px]">
          <h2 class="flex text-[#121F3E] text-[32px] font-bold font-sans mt-2 mb-4">
            Privacy Policy
          </h2>
          <p class="flex text-[#121F3E] text-[16px] font-normal font-sans mb-[14.4px] text-left">
            Last updated: 04/18/2021
            <br />
            Introduction
          </p>
          <p class="flex text-[#121F3E] font-normal text-[16px] mb-[14.4px] font-sans">
            Welcome to One For All Corporation
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Communications
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation (“us”, “we”, or “our”) operates
            https://bankonhero.com and Hero mobile application (hereinafter
            referred to as “Service”).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Privacy Policy governs your visit to https://bankonhero.com and
            Hero mobile application, and explains how we collect, safeguard and
            disclose information that results from your use of our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Terms and Conditions (“Terms”) govern all use of our Service and
            together with the Privacy Policy constitutes your agreement with us
            (“agreement”).
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Definitions
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            SERVICE means the https://bankonhero.com website and Hero mobile
            application operated by One For All Corporation
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            PERSONAL DATA means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            COOKIES are small files stored on your device (computer or mobile
            device).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            DATA CONTROLLER means a natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal data are, or are to be,
            processed. For the purpose of this Privacy Policy, we are a Data
            Controller of your data.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            DATA SUBJECT is any living individual who is the subject of Personal
            Data.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Information Collection and Use
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            THE USER is the individual using our Service. The User corresponds
            to the Data Subject, who is the subject of Personal Data.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Information Collection and Use
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            collect several different types of information for various purposes
            to provide and improve our Service to you.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Information Collection and Use
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Personal Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (“Personal Data”). Personally identifiable information
            may include, but is not limited to:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • Email address
            <br />
            • First name and last name
            <br />
            • Phone number
            <br />
            • Address, State, Province, ZIP/Postal code, City
            <br />
            • Cookies and Usage Data
            <br />
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Usage Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through a mobile
            device (“Usage Data”).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            This Usage Data may include information such as your computer’s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that you visit, the time and date
            of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            When you access Service with a mobile device, this Usage Data may
            include information such as the type of mobile device you use, your
            mobile device unique ID, the IP address of your mobile device, your
            mobile operating system, the type of mobile Internet browser you
            use, unique device identifiers and other diagnostic data.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Location Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may use and store information about your location if you give us
            permission to do so (“Location Data”). We use this data to provide
            features of our Service, to improve and customize our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can enable or disable location services when you use our Service
            at any time by way of your device settings.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Tracking Cookies Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Examples of Cookies we use:
            <br />
            • Session Cookies: We use Session Cookies to operate our Service.
            <br />
            • Preference Cookies: We use Preference Cookies to remember your
            preferences and various settings.
            <br />
            • Security Cookies:&nbsp;We use Security Cookies for security
            purposes.
            <br />
            • Advertising Cookies: Advertising Cookies are used to serve you
            with advertisements that may be relevant to you and your interests.
            <br />
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Other Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements, non-disclosure agreements, information on bonuses and
            compensation, information on marital status, family members, social
            security (or other taxpayer identification) number, office location
            and other data.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Use of Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation uses the collected data for various
            purposes:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to provide and maintain our Service;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to notify you about changes to our Service;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to allow you to participate in interactive features of our Service
            when you choose to do so;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to gather analysis or valuable information so that we can improve
            our Service;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to detect, prevent and address technical issues;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to fulfill any other purpose for which you provide it;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to provide you with notices about your account and/or subscription,
            including expiration and renewal notices, email-instructions, etc.;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            to provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            in any other way we may describe when you provide the information;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            for any other purpose with your consent.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Retention of Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Transfer of Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your information, including Personal Data, may be transferred to –
            and maintained on – computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation will take all the steps reasonably necessary
            to ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Disclosure of Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Disclosure for Law Enforcement.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Under certain circumstances, we may be required to disclose your
            Personal Data if required to do so by law or in response to valid
            requests by public authorities.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Business Transaction.
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Other cases. We may disclose your information also:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • to our subsidiaries and affiliates;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • to contractors, service providers, and other third parties we use
            to support our business;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • to fulfill the purpose for which you provide it;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • for the purpose of including your company’s logo on our website;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • for any other purpose disclosed by us when you provide the
            information;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • with your consent in any other cases;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            • if we believe disclosure is necessary or appropriate to protect
            the rights, property, or safety of the Company, our customers, or
            others.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Mixpanel
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Mixpanel is provided by Mixpanel Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can prevent Mixpanel from using your information for analytics
            purposes by opting-out. To opt-out of Mixpanel service, please visit
            this page: https://mixpanel.com/optout/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on what type of inform`ation Mixpanel collects,
            please visit the Terms of Use page of Mixpanel:
            https://mixpanel.com/terms/
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Security of Data
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your Data Protection Rights Under General Data Protection Regulation
            (GDPR)
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right to access, update or to delete the information we have on
            you;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right to object. You have the right to object to our processing
            of your Personal Data;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right to data portability. You have the right to be provided
            with a copy of your Personal Data in a structured, machine-readable
            and commonly used format;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            the right to withdraw consent. You also have the right to withdraw
            your consent at any time where we rely on your consent to process
            your personal information;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Your Data Protectio Rights under the California Privacy Protection
            Act (CalOPPA)
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require a person or
            company in the United States (and conceivable the world) that
            operates websites collecting personally identifiable information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals with whom it is being shared, and to comply with
            this policy. – See more at:
            https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            According to CalOPPA we agree to the following:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            users can visit our site anonymously;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            our Privacy Policy link includes the word “Privacy”, and can easily
            be found on the page specified above on the home page of our
            website;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            users will be notified of any privacy policy changes on our Privacy
            Policy Page;
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            users are able to change their personal information by emailing us
            at hello@bankonhero.com.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Policy on “Do Not Track” Signals:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We honor Do Not Track signals and do not track, plant cookies, or
            use advertising when a Do Not Track browser mechanism is in place.
            Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Service Providers
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Analytics
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google Analytics
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualise and personalise the ads of its own advertising
            network.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
            https://policies.google.com/privacy?hl=en
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We also encourage you to review the Google’s policy for safeguarding
            your data: https://support.google.com/analytics/answer/6004245.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Piwik / Matomo
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Piwik or Matomo is a web analytics service. You can visit their
            Privacy Policy page here: https://matomo.org/privacy-policy
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Cloudflare analytics
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Cloudflare analytics is a web analytics service operated by
            Cloudflare Inc. Read the Privacy Policy here:
            https://www.cloudflare.com/privacypolicy/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Mixpanel
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Mixpanel is provided by Mixpanel Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can prevent Mixpanel from using your information for analytics
            purposes by opting-out. To opt-out of Mixpanel service, please visit
            this page: https://mixpanel.com/optout/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on what type of information Mixpanel collects,
            please visit the Terms of Use page of Mixpanel:
            https://mixpanel.com/terms/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            CI/CD tools
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            GitHub
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            GitHub is provided by GitHub, Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            GitHub is a development platform to host and review code, manage
            projects, and build software.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on what data GitHub collects for what purpose
            and how the protection of the data is ensured, please visit GitHub
            Privacy Policy page:
            https://help.github.com/en/articles/github-privacy-statement.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Advertising
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google AdSense DoubleClick Cookie
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google, as a third party vendor, uses cookies to serve ads on our
            Service. Google’s use of the DoubleClick cookie enables it and its
            partners to serve ads to our users based on their visit to our
            Service or other websites on the Internet.
          </p>
          You may opt out of the use of the DoubleClick Cookie for
          interest-based advertising by visiting the Google Ads Settings web
          page: http://www.google.com/ads/preferences/
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Bing Ads
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Bing Ads is an advertising service provided by Microsoft Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out from Bing Ads by following the instructions on Bing
            Ads Opt-out page:
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information about Bing Ads, please visit their Privacy
            Policy: https://privacy.microsoft.com/en-us/PrivacyStatement
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            AdMob by Google
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            AdMob by Google is provided by Google Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out from the AdMob by Google service by following the
            instructions described by Google:
            https://support.google.com/ads/answer/2662922?hl=en
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on how Google uses the collected information,
            please visit the “How Google uses data when you use our partners’
            sites or app” page: http://www.google.com/policies/privacy/partners/
            or visit the Privacy Policy of Google:
            http://www.google.com/policies/privacy/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on how Google uses the collected information,
            please visit the “How Google uses data when you use our partners’
            sites or app” page: http://www.google.com/policies/privacy/partners/
            or visit the Privacy Policy of Google:
            http://www.google.com/policies/privacy/
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            AdButler
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            AdButler is an advertising service provided by Sparklit Networks
            Inc.
            <br />
            For more information about AdButler, please visit their Privacy
            Policy: https://www.sparklit.com/agreements.spark?agreement=privacy
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Advertising
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            One For All Corporation uses remarketing services to advertise on
            third party websites to you after you visited our Service. We and
            our third-party vendors use cookies to inform, optimise and serve
            ads based on your past visits to our Service.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google Ads (AdWords)
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page: http://www.google.com/settings/ads
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on – https://tools.google.com/dlpage/gaoptout – for your
            web browser. Google Analytics Opt-out Browser Add-on provides
            visitors with the ability to prevent their data from being collected
            and used by Google Analytics.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:
            https://policies.google.com/privacy?hl=en
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Bing Ads Remarketing
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Bing Ads remarketing service is provided by Microsoft Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out of Bing Ads interest-based ads by following their
            instructions:
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can learn more about the privacy practices and policies of
            Microsoft by visiting their Privacy Policy page:
            https://privacy.microsoft.com/en-us/PrivacyStatement
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Twitter
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Twitter remarketing service is provided by Twitter Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out from Twitter’s interest-based ads by following their
            instructions: https://support.twitter.com/articles/20170405
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:
            https://twitter.com/privacy
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Facebook
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Facebook remarketing service is provided by Facebook Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can learn more about interest-based advertising from Facebook by
            visiting this page: https://www.facebook.com/help/164968693837950
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            To opt-out from Facebook’s interest-based ads, follow these
            instructions from Facebook:
            https://www.facebook.com/help/568137493302217
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA
            http://www.aboutads.info/choices/, the Digital Advertising Alliance
            of Canada in Canada http://youradchoices.ca/ or the European
            Interactive Digital Advertising Alliance in Europe
            http://www.youronlinechoices.eu/, or opt-out using your mobile
            device settings.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            For more information on the privacy practices of Facebook, please
            visit Facebook’s Data Policy:
            https://www.facebook.com/privacy/explanation
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Pinterest
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Pinterest remarketing service is provided by Pinterest Inc.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can opt-out from Pinterest’s interest-based ads by enabling the
            “Do Not Track” functionality of your web browser or by following
            Pinterest instructions:
            http://help.pinterest.com/en/articles/personalization-and-data
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You can learn more about the privacy practices and policies of
            Pinterest by visiting their Privacy Policy page:
            https://about.pinterest.com/en/privacy-policy
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Links to Other Sites
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Children’s Privacy
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            Our Services are not intended for use by children under the age of
            18 (“Child” or “Children”).
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Changes to This Privacy Policy
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            “effective date” at the top of this Privacy Policy.
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h5 class="flex text-[#121F3E] font-bold text-[20px] mb-4 mt-2 font-sans">
            Contact Us
          </h5>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[14.4px]">
            If you have any questions about this Privacy Policy, please contact
            us:
          </p>
          <p class="flex text-[#121F3E] font-normal text-base text-left font-sans mb-[40px]">
            By email: hello@bankonhero.com.
            <br />
            By mail: 140 Yonge Street Suite 200 Toronto, ON M5C 1X6.
          </p>
        </div>
      </section>
    </div>
  );
};
function PrivacyPolicy() {
  return (
    <div class=" h-screen">
      <nav class=" flex items-center justify-between px-2 py-3 mb-1 ">
        <div class="flex xl:w-[1140px] 2xl:w-[1140px] w-full mx-auto justify-between">
          <div class="w-full relative flex justify-start  lg:static lg:justify-start">
            <img
              src={Logo}
              class="w-[200px] h-auto auto-[300/82]"
              alt="logo"
            />
          </div>
          <div class="flex justify-center">
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-[150px] text-white font-bold font-sans rounded-full"
              // onClick={() => nextButtonClicked()}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </nav>
      <div>{PrivacyMainView()}</div>
    </div>
  );
}

export default PrivacyPolicy;
