import './App.css';
import { BrowserRouter as Router } from  'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Home from './screens/home';
import Landing from './screens/land/index';
import Add from './screens/add';
import PrivacyPolicy from './screens/privacyPolicy';
import TermsService from './screens/termsService';
import ContactUs from './screens/contactUs';
import ThankYou from './screens/thankYou';

function App() {
  return (
    <div class='App'>
      <Router>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/add-1' element={<Landing/>}/>
          <Route exact path='/add' element={<Add/>}/>
          <Route exact path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route exact path='/terms-of-service' element={<TermsService/>}/>
          <Route exact path='/contact' element={<ContactUs/>}/>
          <Route exact path='/thank-you' element={<ThankYou/>}/>
        </Routes>
      </Router>
    </div>
  )
}

export default App;
