import React, {useState} from "react";
import Header from "../../components/header";
import GetQuote from './getQuote';

function Pages() {
  return (
    <Header>
      <GetQuote />
    </Header>
  );
}

export default Pages;
