import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../../components/alertMessage";
import SelectOption from "../../components/selectOption";
import { Dates } from "../../utils";
import moment from 'moment';

function GetQuote() {
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  const [sex, setSex] = useState(null);
  const [formValues, setFormValues] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [formErrors, setFormErrors] = useState({
    gender: false,
    birthday: false,
  });
  const [pastYears, setPastYears] = useState([]);

  useEffect(() => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(100), (val, index) => year - index);
    setPastYears(["Year", ...years]);
    setIsSubmit(false);
  }, []);

  useEffect(() => {
    let date = formValues.day + '/' + formValues.month  + '/' + formValues.year;
    let dateOfBirth = moment(date).format("DD/MM/YYYY");
    if (isSubmit) {
      if (!formErrors.gender && !formErrors.birthday) {
        navigate("/add?sex=" + sex + '&dateOfBirth=' + dateOfBirth);
      }
    }
    setIsSubmit(false);
  }, [isSubmit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const nextStep = () => {
    if (sex === null) {
      setFormErrors((prevState) => ({
        ...prevState,
        gender: true,
      }));
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        gender: false,
      }));
    }
    if (
      formValues.day === "" || formValues.day === "Day" || 
      formValues.month === "" || formValues.month === 'Month' ||
      formValues.year === "" || formValues.year === 'Year'
    ) {
      setFormErrors((prevState) => ({
        ...prevState,
        birthday: true,
      }));
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        birthday: false,
      }));
    }
    setTimeout(() => {
      setIsSubmit(true);
    }, 300);
  };

  return (
    <div class="bg-[url(https://herolifeinsurance.ca/wp-content/uploads/2021/12/Screenshot_8.png)] bg-no-repeat bg-center mt-8 mb-4 ml-0 mr-0 pt-8 pb-8">
      <h1 class="text-5xl font-bold font-sans mt-4">Get a Quote & Buy</h1>
      <div class="p-6 w-full">
        <div class="flex w-full max-w-lg mx-auto my-auto p-7 mb-16 border rounded-3xl flex-col bg-white">
          {(formErrors.gender || formErrors.birthday) && (
            <AlertMessage
              icon={true}
              message={
                "There was a problem with your submission. Please review the fields below."
              }
            />
          )}
          <div class="flex w-full flex-col">
            <div class="w-full flex mb-2">
              <p class="flex text-sm items-center font-semibold font-sans text-[#555]">
                Gender
              </p>
            </div>
            <div class="flex w-full">
              <div class="flex w-full">
                <div class="flex justify-between w-full flex-col sm:flex-row">
                  <div class="flex w-full md:max-w-[219px] border rounded-lg  sm:mb-0 mb-3 hover:border-[#1e86ff]">
                    <p
                      for="creditCard"
                      class={`w-full h-10 cursor-pointer rounded-lg flex justify-center items-center font-medium font-sans text-base
                        ${sex === "Man" ? "bg-[#1D86FF]" : "bg-white"} 
                        ${sex === "Man" ? "text-white" : "text-[#96A0B5]"} 
                      `}
                      onClick={() => {
                        setSex("Man");
                      }}
                    >
                      Male
                    </p>
                  </div>
                  <div class="border w-full md:max-w-[219px] flex rounded-lg hover:border-[#1e86ff]">
                    <p
                      for="preAuthorizedChequing"
                      class={`w-full h-10 cursor-pointer rounded-lg flex justify-center items-center font-medium font-sans text-base
                        ${sex === "Female" ? "bg-[#1D86FF]" : "bg-white"}
                        ${sex === "Female" ? "text-white" : "text-[#96A0B5]"} 
                      `}
                      onClick={() => {
                        setSex("Female");
                      }}
                    >
                      Female
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {formErrors.gender && (
            <AlertMessage icon={false} message={"This filed is required"} />
          )}
          <div class="flex w-full flex-col mt-6">
            <div class="w-full flex mb-2">
              <p class="flex text-sm items-center font-semibold font-sans text-[#555]">
                Date of Birth
              </p>
            </div>
            <div class="flex w-full justify-between">
              <div class="w-full">
                <SelectOption
                  value={formValues.day}
                  onChange={handleChange}
                  values={Dates.day}
                  name="day"
                />
              </div>
              <div class="w-full px-3">
                <SelectOption
                  value={formValues.month}
                  onChange={handleChange}
                  values={Dates.month}
                  name="month"
                />
              </div>
              <div class="w-full">
                <SelectOption
                  value={formValues.year}
                  onChange={handleChange}
                  values={pastYears}
                  name="year"
                />
              </div>
            </div>
          </div>
          {formErrors.birthday && (
            <AlertMessage icon={false} message={"This filed is required"} />
          )}
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-normal font-sans px-[35px] py-[12px] rounded-full mx-auto flex mt-5 mb-5"
            onClick={nextStep}
          >
            Get My Quote
          </button>
        </div>
      </div>
    </div>
  );
}

export default GetQuote;
