export const Dates = {
  day: [
    "Day",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
  ],
  month: [
    "Month",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  year: ["Year", 2022],
  dayOrder: [
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21th",
    "22th",
    "23th",
    "24th",
    "25th",
    "26th",
    "27th",
    "28th",
  ],
  coverages: [
    "--- Select One ---",
    "No Medical Guaranteed Life Insurance",
    "Term Insurance",
    "Accidental Death & Dismemberment",
    "Critical Illness Insurance",
  ],
};

export const PlanData = [
  {
    option: "1",
    MonthlyPremium: "30.00",
    Benefit: "250.000",
  },
  {
    option: "2",
    MonthlyPremium: "35.00",
    Benefit: "300.000",
  },
  {
    option: "3",
    MonthlyPremium: "40.00",
    Benefit: "350.000",
  },
  {
    option: "4",
    MonthlyPremium: "45.00",
    Benefit: "400.000",
  },
  {
    option: "5",
    MonthlyPremium: "50.00",
    Benefit: "450.000",
  },
  {
    option: "6",
    MonthlyPremium: "55.00",
    Benefit: "500.000",
  },
];

export const RelationshipData = [
  "Mother",
  "Father",
  "Sister",
  "Brother",
  "Daughter",
  "Son",
  "Spouse",
  "Common Law Spouse",
  "Aunt",
  "Uncle",
  "Cousin",
  "Friend",
  "Estate",
  "Other",
];

export const OurClients = [
  {
    name: "Abhi, Mississauga ON",
    profile:
      "Got Critical Illness from the company. Gotta say it was a pretty simple and straightforward experience. My agent helped me understand the specifics of my coverage and guided me to choose the best options based on what I wanted which was a return of premium option. Highly recommended!",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/ab.jpg",
  },
  {
    name: "Molly, Richmond BC",
    profile:
      "Hero Life Insurance policy just perfect for me!! The rep I spoke with did a great job in explaining in me the coverage. Explained everything thoroughly while being courteous and patient. Highly recommend them as they were easy to apply with.",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/Molly_Leee.jpg",
  },
  {
    name: "Edward, Toronto ON",
    profile:
      "The agent was terrific! He was very clear in outlining the policy and answered any question I had with patience. Made it very simple and easy for me to get approved.",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/Edward.jpg",
  },
  {
    name: "Karen, Regina SK",
    profile:
      "I have never found insurance so easy to purchase. Both people I spoke to were completely professional, easy to talk to and they listened to my needs. I highly recomment this company to anyone for final expense insurance.",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/Ellipse-2586.jpg",
  },
  {
    name: "Thelma, Calgary AB",
    profile:
      "The insurance advisor was very knowledgeable, informative, clear in details, courteous and very patient in answering all my questions. The coverage I got was right for me and very easy to apply for.",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/Ellipse-2586-1.jpg",
  },
  {
    name: "Stella, Etobicoke ON",
    profile:
      "You did a great job in explaining the policy to me. All was very understanding. I am very happy to have decided to get life insurance. Should have done this years ago. I have already given the company name to a few of my friends that haven’t thought of getting coverage.",
    avatar: "https://herolifeinsurance.ca/wp-content/uploads/2021/12/Ellipse-2586-2-1.jpg",
  },
];

export const HelpDifficults = [
  {
    title: 'What If I Have An Illness?',
    description: 'At Hero Life Insurance, we believe no one should be denied coverage. No matter your situation we’ll help you get the right coverage.',
  },
  {
    title: 'Are Your Rates Competitive?',
    description: 'Absolutely, we shop with 25+ insurance companies and the leading Canadian banks to give you the best rates possible.',
  },
  {
    title: 'Where Can I Go To Learn More?',
    description: 'We’re always here to help. You can reach by email at hello@herolifeinsurance.ca or call us anytime at (844) 790-1253.',
  }
]

export * from "./api-const";
