import React from "react";

function SelectButton(props) {
  return (
    <button
      class={`h-14 w-full font-bold font-sans border rounded-lg hover:border-[#1e86ff] ${
        props.selected ? "bg-blue-500" : "bg-white"
      } ${props.selected ? "text-white" : "text-[#96a0b5]"} ${
        props.selected ? "border-none" : "border-gray-200"
      }
      `}
      onClick={props.onChange}
      type={props.type}
      disabled={props.disabled}
      name={props.name}
    >
      {props.name}
    </button>
  );
}
export default SelectButton;
