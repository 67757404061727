import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../components/header";
import StepFirst from "./stepFirst";
import StepSecond from "./stepSecond";
import StepThird from "./stepThird";
import StepFourth from "./stepFourth";
import StepThanks from "./stepThanks";
import axios from "axios";
import { API_END_POINTS } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

function Add() {
  const [finalstep, setFinalStep] = useState(false);
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(1);

  const [formValues, setFormValues] = useState({
    ID: "",
    sex: "",
    dateOfBirth: "",
    firstName: "",
    lastName: "",
    country: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    email: "",
    paymentMothod: "",
    basicBenefitAmuont: "",
    premiumAmount: "",
    beneficiaryName1: "",
    beneficiaryName2: "",
    beneficiaryName3: "",
    beneficiaryName4: "",
    beneficiaryName5: "",
    beneficiaryName6: "",
    relationShip1: "",
    relationShip2: "",
    relationShip3: "",
    relationShip4: "",
    relationShip5: "",
    relationShip6: "",
    beneficiaryDateOfBirth1: "",
    beneficiaryDateOfBirth2: "",
    beneficiaryDateOfBirth3: "",
    beneficiaryDateOfBirth4: "",
    beneficiaryDateOfBirth5: "",
    beneficiaryDateOfBirth6: "",
    transitNumber: "",
    financialNumber: "",
    accountNumber: "",
    withdrawnDate: "",
    payorName: "",
    signatureImage: "",
  });

  useEffect(() => {
    const sexValue = searchParams.get("sex");
    const dateOfBirthValue = searchParams.get("dateOfBirth");
    setFormValues((prevState) => ({
      ...prevState,
      sex: sexValue,
      dateOfBirth: dateOfBirthValue,
    }));
  }, []);

  useEffect(() => {
    if (formValues.paymentMothod !== '') {
      const unique_id = uuidv4();
      const small_id = unique_id.slice(0,8);
      
      let formData = {
        ...formValues,
        ID: small_id,
      }

      axios({
        method: "post",
        url: API_END_POINTS.ADD_FORM,
        data: formData,
        headers: { 
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/json", 
        },
      })
        .then((response) => {
          console.log('Success Uploading PDF and sent email')
        })
    }

  }, [finalstep]);


  const nextStep = (info) => {
    if (info.step === 1) {
      setFormValues((prevState) => ({
        ...prevState,
        city: info.formData.city,
        country: info.formData.country,
        email: info.formData.email,
        firstName: info.formData.firstName,
        lastName: info.formData.lastName,
        phoneNumber: info.formData.phoneNumber,
        postalCode: info.formData.postalCode,
        province: info.formData.province,
        streetAddress: info.formData.streetAddress,
      }));
    } else if (info.step === 2) {
      setFormValues((prevState) => ({
        ...prevState,
        basicBenefitAmuont: info.formData.basicBenefitAmuont,
        premiumAmount: info.formData.premiumAmount,
      }));
    } else if (info.step === 3) {
      info.formData.map((item, id) => {
        let date = item.day + "/" + item.month + "/" + item.year;
        let dateOfBirth = moment(date).format("DD/MM/YYYY");
        setFormValues((prevState) => ({
          ...prevState,
          [`beneficiaryName${id + 1}`]: item.firstName + " " + item.lastName,
          [`relationShip${id + 1}`]: item.relationship,
          [`beneficiaryDateOfBirth${id + 1}`]: dateOfBirth,
        }));
      });
    } else if (info.step === 4) {
      if(info.formData.cardName) {
        setFormValues((prevState) => ({
          ...prevState,
          financialNumber: info.formData.cardNumber,
          transitNumber: info.formData.cardNumber,
          accountNumber: info.formData.cardNumber,
          paymentMothod: "Credit  Card",
          payorName: info.formData.cardName,
          withdrawnDate: info.formData.day,
          signatureImage: info.formData.signatureImage,
        }));
      } else {
        setFormValues((prevState) => ({
          ...prevState,
          financialNumber: info.formData.financialNumber,
          transitNumber: info.formData.branchNumber,
          accountNumber: info.formData.accountNumber,
          paymentMothod: "PAD",
          payorName: info.formData.firstNameCheque + " " + info.formData.lastNameCheque,
          withdrawnDate: info.formData.day,
          signatureImage: info.formData.signatureImage,
        }));
      }
      setFinalStep(true);
    }

    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };
  
  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return <StepFirst nextStep={nextStep} />;
      case 2:
        return <StepSecond nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <StepThird nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <StepFourth nextStep={nextStep} prevStep={prevStep} />;
      case 5:
        return <StepThanks />;
      default:
        console.log("success");
    }
  };

  return <Header>{renderSwitch(step)}</Header>;
}

export default Add;
