import React, { useState, useEffect, useRef } from "react";
import AlertMessage from "../../components/alertMessage";
import SelectOption from "../../components/selectOption";
import { Dates } from "../../utils";
import SelectButton from "../../components/selectButton";
import { HiOutlineRefresh } from "react-icons/hi";
import InputMask from "react-input-mask";
import SignatureView from "../../components/signatureView";
import SignaturePad from "react-signature-canvas";
import axios from "axios";
import { API_END_POINTS } from "../../utils";

function StepFourth({ nextStep, prevStep }) {
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [showPad, setShowPad] = useState(false);
  const [errorInForm, setErrorInForm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [formErrors, setFormErrors] = useState({
    cardName: false,
    cardNumber: false,
    expiry: false,
    cvc: false,
    creditCard: false,
    pad: false,
    financialName: false,
    financialNumber: false,
    branchNumber: false,
    accountNumber: false,
    firstNameCheque: false,
    lastNameCheque: false,
    signatureImage: false,
  });

  let sigPad = useRef({});

  const [formValues, setFormValues] = useState({
    signatureImage: "",
    agreePayment: false,
    creditCardInfo: {
      cardName: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
      day: "1st",
    },
    padInfo: {
      financialName: "",
      financialNumber: "",
      branchNumber: "",
      accountNumber: "",
      firstNameCheque: "",
      lastNameCheque: "",
      day: "1st",
    },
  });

  useEffect(() => {
    setShowCreditCard(false);
    setShowPad(false);
  }, []);

  useEffect(() => {
    if (isSubmit) {
      if (
        !formErrors.cardName &&
        !formErrors.cardNumber &&
        !formErrors.expiry &&
        !formErrors.cvc &&
        formValues.agreePayment
      ) {
        setErrorInForm(false);
        let returnFormValues = {};
        if (showPad) {
          returnFormValues = {
            ...formValues.padInfo,
            signatureImage: formValues.signatureImage,
          };
        } else {
          returnFormValues = {
            ...formValues.creditCardInfo,
            signatureImage: formValues.signatureImage,
          };
        }
        nextStep({ step: 4, formData: returnFormValues });
      } else if (
        !formErrors.financialName &&
        !formErrors.financialNumber &&
        !formErrors.branchNumber &&
        !formErrors.accountNumber &&
        !formErrors.firstNameCheque &&
        !formErrors.lastNameCheque &&
        formValues.agreePayment
      ) {
        setErrorInForm(false);
        // nextStep();
        let returnFormValues = {};
        if (showPad) {
          returnFormValues = {
            ...formValues.padInfo,
            signatureImage: formValues.signatureImage,
          };
        } else {
          returnFormValues = {
            ...formValues.creditCardInfo,
            signatureImage: formValues.signatureImage,
          };
        }
        nextStep({ step: 4, formData: returnFormValues });
      } else {
        setErrorInForm(true);
      }
    }

    setIsSubmit(false);
  }, [isSubmit]);

  const initForm = () => {
    setFormValues({
      agreePayment: false,
      creditCardInfo: {
        cardName: "",
        cardNumber: "",
        expiry: "",
        cvc: "",
        day: "1st",
      },
      padInfo: {
        financialName: "",
        financialNumber: "",
        branchNumber: "",
        accountNumber: "",
        firstNameCheque: "",
        lastNameCheque: "",
        day: "1st",
      },
    });
    setFormErrors({
      cardName: false,
      cardNumber: false,
      expiry: false,
      cvc: false,
      creditCard: false,
      pad: false,
      financialName: false,
      financialNumber: false,
      branchNumber: false,
      accountNumber: false,
      firstNameCheque: false,
      lastNameCheque: false,
    });
  };

  const clickCredit = () => {
    setShowCreditCard(true);
    setShowPad(false);
    initForm();
  };

  const clickPad = () => {
    setShowCreditCard(false);
    setShowPad(true);
    initForm();
  };

  const showCardView = () => {
    if (showCreditCard) {
      return CreditSection();
    } else if (showPad) {
      return PadSection();
    } else {
      return null;
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (showCreditCard) {
      if (name === "agreePayment") {
        setFormValues((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        setFormValues((prevState) => ({
          ...prevState,
          creditCardInfo: {
            ...prevState.creditCardInfo,
            [name]: value,
          },
        }));
      }
    } else {
      if (name === "agreePayment") {
        setFormValues((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        setFormValues((prevState) => ({
          ...prevState,
          padInfo: {
            ...prevState.padInfo,
            [name]: value,
          },
        }));
      }
    }
  };

  const CreditSection = () => {
    return (
      <div class="w-full mt-10">
        <label class=" flex tracking-wide text-[#121f3e] text-lg font-medium font-sans mb-4">
          Cardholder Information
        </label>

        <div class="flex flex-wrap mb-4 ">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0 mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Name on card
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.creditCardInfo.cardName}
              name="cardName"
              onChange={handleChange}
            />
            {formErrors.cardName && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5 mt-4 md:mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Credit Card Number
            </label>
            <InputMask
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              mask="9999-9999-9999-9999"
              value={formValues.creditCardInfo.cardNumber}
              name="cardNumber"
              onChange={handleChange}
            />
            {formErrors.cardNumber && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
        </div>

        <div class="flex flex-wrap mb-4 ">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0 mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Expiry Date
            </label>
            <InputMask
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              mask="99/99"
              value={formValues.creditCardInfo.expiry}
              name="expiry"
              onChange={handleChange}
            />
            {formErrors.expiry && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5 mt-4 md:mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              CVC
            </label>
            <InputMask
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              mask="999"
              value={formValues.creditCardInfo.cvc}
              name="cvc"
              onChange={handleChange}
            />
            {formErrors.cvc && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
        </div>

        <div class="w-full mb-4">
          <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2 mt-6">
            I wish to have my premiums withdrawn on the _____ day of each month.
          </label>
          <SelectOption
            value={formValues.creditCardInfo.day}
            onChange={handleChange}
            values={Dates.dayOrder}
            name="day"
          />
        </div>
      </div>
    );
  };

  const PadSection = () => {
    return (
      <div class="w-full mt-10">
        <img
          class="w-407 h-193 object-contain mb-6"
          src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/cheque.gif"
          alt=""
        />

        <div class="flex flex-wrap mb-4 ">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0 mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Financial Institution Name
            </label>

            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.financialName}
              name="financialName"
              onChange={handleChange}
            />
            {formErrors.financialName && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5 mt-4 md:mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Financial Institution Number
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.financialNumber}
              name="financialNumber"
              onChange={handleChange}
            />
            {formErrors.financialNumber && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
        </div>

        <div class="flex flex-wrap mb-4 ">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0 mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Branch Transit Number
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.branchNumber}
              name="branchNumber"
              onChange={handleChange}
            />
            {formErrors.branchNumber && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5 mt-4 md:mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Account Number
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.accountNumber}
              name="accountNumber"
              onChange={handleChange}
            />
            {formErrors.accountNumber && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
        </div>

        <div class="flex flex-wrap mb-4 ">
          <div class="w-full md:w-1/2 md:pr-1.5 mb-2 md:mb-0 mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              First Name on Cheque
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.firstNameCheque}
              name="firstNameCheque"
              onChange={handleChange}
            />
            {formErrors.firstNameCheque && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
          <div class="w-full md:w-1/2 md:pl-1.5 mt-4 md:mt-2">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Last Name on Cheque
            </label>
            <input
              class="flex w-full placeholder-[#7a7a7a] text-[#96a0b5] focus:text-[#121f3e] text-base font-medium font-sans border border-gray-200 rounded-lg py-2 px-4 h-14 focus:outline-none focus:bg-white focus:border-2 focus:border-blue-500"
              type="text"
              value={formValues.padInfo.lastNameCheque}
              name="lastNameCheque"
              onChange={handleChange}
            />
            {formErrors.lastNameCheque && (
              <AlertMessage icon={false} message={"This filed is required"} />
            )}
          </div>
        </div>

        <div class="w-full mb-4">
          <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2 mt-6">
            I wish to have my premiums withdrawn on the _____ day of each month.
          </label>
          <SelectOption
            value={formValues.padInfo.day}
            onChange={handleChange}
            values={Dates.dayOrder}
            name="day"
          />
        </div>
      </div>
    );
  };

  const nextButtonClicked = () => {
    // save();
    if (formValues.creditCardInfo.cardName === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        cardName: true,
      }));
    }
    if (formValues.creditCardInfo.cardNumber === "" || formValues.creditCardInfo.cardNumber.replace(/-|_/g, "").length !== 16) {
      setFormErrors((prevState) => ({
        ...prevState,
        cardNumber: true,
      }));
    }
    if (formValues.creditCardInfo.expiry === "" || formValues.creditCardInfo.expiry.replace(/-|_/g, "").length !== 5) {
      setFormErrors((prevState) => ({
        ...prevState,
        expiry: true,
      }));
    }
    if (formValues.creditCardInfo.cvc === "" || formValues.creditCardInfo.cvc.replace(/-|_/g, "").length !== 3) {
      setFormErrors((prevState) => ({
        ...prevState,
        cvc: true,
      }));
    }

    if (formValues.padInfo.financialName === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        financialName: true,
      }));
    }
    if (formValues.padInfo.financialNumber === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        financialNumber: true,
      }));
    }
    if (formValues.padInfo.branchNumber === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        branchNumber: true,
      }));
    }
    if (formValues.padInfo.accountNumber === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        accountNumber: true,
      }));
    }
    if (formValues.padInfo.firstNameCheque === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        firstNameCheque: true,
      }));
    }
    if (formValues.padInfo.lastNameCheque === "") {
      setFormErrors((prevState) => ({
        ...prevState,
        lastNameCheque: true,
      }));
    }
    if (!formErrors.signatureImage) {
      setFormErrors((prevState) => ({
        ...prevState,
        signatureImage: true,
      }));
    } else {
      save();
    }
  };

  const previousButtonClicked = () => {
    prevStep();
  };

  const clear = () => {
    sigPad.current.clear();
  };

  const save = () => {
    const formData = {
      image: sigPad.current.getTrimmedCanvas().toDataURL("image/png"),
    };

    axios({
      method: "post",
      url: API_END_POINTS.UPLOAD_IMAGE,
      data: formData,
      headers: { 
        'Access-Control-Allow-Origin': '*',
        "Content-Type": "multipart/form-data", 
      },
    })
      .then((response) => {
        setFormValues((prevState) => ({
          ...prevState,
          signatureImage: response.data.data,
        }));
        setTimeout(() => {
          setIsSubmit(true);
        }, 300);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div class="mx-auto mb-8">
      <div class="w-full bg-blue-600 items-center justify-center">
        <p class="text-white font-semibold text-3xl font-sans items-center p-6">
          Accidental Death & Dismemberment Insurance
        </p>
      </div>
      <div class="bg-white w-full xl:w-[1140px] mx-auto mt-20 flex lg:flex-row flex-col">
        <div class="w-full lg:w-[604px] p-8 rounded-xl border">
          {(showCreditCard &&
            (formErrors.cardName ||
              formErrors.cardNumber ||
              formErrors.expiry ||
              formErrors.cvc)) ||
          (showPad &&
            (formErrors.financialName ||
              formErrors.financialNumber ||
              formErrors.branchNumber ||
              formErrors.accountNumber ||
              formErrors.firstNameCheque ||
              formErrors.lastNameCheque)) ? (
            <AlertMessage
              icon={true}
              message={
                "There was a problem with your submission. Please review the fields below."
              }
            />
          ) : null}
          <div class="w-full">
            <p class="text-[#767676] text-[13px] text-left font-normal flex items-center">
              Step 4 of 4
            </p>
          </div>
          <div class="w-full my-6">
            <p class="text-[#121f3e] text-[22px] font-semibold font-sans text-left flex items-center mt-2 mb-4">
              Payment Details
            </p>
            <p class="text-[#7a7a7a] text-base font-normal text-left flex items-center mt-2 mb-4">
              Specialty Life Insurance uses secured credit card payment software
              provided by Converge. Converge is a Payment Card Industry (PCI)
              compliant payment processing firm, using SSL-encrypted processing
              technology to safeguard and transmit your payment information over
              secure channels.
            </p>
          </div>

          <div class="flex flex-wrap w-full">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Payment Method
            </label>
            <div class="flex w-full justify-between ">
              <div class="w-full">
                <SelectButton
                  name="Credit Card"
                  selected={showCreditCard}
                  onChange={clickCredit}
                  type={"button"}
                />
              </div>
              <div class="w-full ml-4">
                <SelectButton
                  name="PAD"
                  selected={showPad}
                  onChange={clickPad}
                  type={"button"}
                />
              </div>
            </div>
          </div>
          {!showCreditCard && !showPad && errorInForm && (
            <AlertMessage icon={false} message={"This filed is required"} />
          )}

          <div class="w-full mt-8 ">{showCardView()}</div>

          <div class="w-full mt-10">
            <label class=" flex tracking-wide text-[#121f3e] text-sm font-medium font-sans mb-2">
              Signature
            </label>
            <div class="w-[300px] h-[180px] bg-white justify-start border-dashed border-2 border-gray-200 cursor-[url(https://herolifeinsurance.ca/wp-content/plugins/gravityformssignature/includes/super_signature/pen.cur),_pointer]">
              <SignaturePad
                ref={sigPad}
                penColor={"black"}
                backgroundColor={"white"}
                save
                onEnd={(data) => setFormErrors({ signatureImage: true })}
                canvasProps={{
                  width: 294,
                  height: 176,
                  class: "sigCanvas",
                }}
              />
            </div>
            {/* <SignatureView 
              innerRef={sigPad}
              penColor={'black'}
              backgroundColor={'white'}
            /> */}
            <button onClick={clear} class="flex ml-[280px]">
              <HiOutlineRefresh class="m-1 text-[#7a7a7a] w-6 h-6" />
            </button>

            {formErrors.signatureImage ? (
              <AlertMessage
                icon={false}
                message={"Please enter your signature."}
              />
            ) : null}

            {(showCreditCard || showPad) && (
              <div class="flex w-full">
                <input
                  type="checkbox"
                  name="agreePayment"
                  value={formValues.agreePayment}
                  class=" form-check-input h-6 w-6 mt-1"
                  onChange={handleChange}
                />
                <h2 class="text-[#96A0B5] font-medium text-base font-sans text-left ml-2 w-full">
                  I authorize Hero Life Insurance to electronically withdraw the
                  insurance premium for this policy on a monthly basis.
                </h2>
              </div>
            )}
            {!formValues.agreePayment &&
              (showCreditCard || showPad) &&
              !isSubmit &&
              errorInForm && (
                <AlertMessage icon={false} message={"This filed is required"} />
              )}
          </div>

          <div class="flex my-auto mt-4 md:justify-between flex-col md:flex-row">
            <button
              class="bg-white h-14 w-full md:w-1/4 text-[#1e86ff] font-bold font-sans rounded-full border-2 border-[#1e86ff]"
              onClick={() => previousButtonClicked()}
            >
              Previous
            </button>
            <button
              class="bg-blue-500  hover:bg-blue-700 h-14 w-full md:w-1/4 text-white font-bold font-sans rounded-full mt-4 md:mt-0"
              onClick={() => nextButtonClicked()}
            >
              Next
            </button>
          </div>
        </div>
        <div class="w-full lg:w-[536px]  p-8">
          <div class="w-full mb-5">
            <h2 class="text-[#121f3e] text-[44px] font-bold font-sans text-center lg:text-left">
              Why do we ask this?
            </h2>
          </div>
          <div class="w-full mb-5">
            <p class="text-[#6d7d93] text-base font-medium font-sans text-left">
              Your residency determines your eligibility for this plan. In
              addition, accurate information is needed should you decide to
              proceed with the purchase of this plan. Please note there are
              absolutely no obligations at this stage.
            </p>
          </div>
          <div class="flex justify-center lg:justify-start">
            <img
              class="auto-[180/87] w-[180px] h-auto object-contain"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/bbb_new_ico-2.jpg"
              alt=""
            />
            <img
              class="auto-[96/105] w-[96px] h-auto object-contain ml-8"
              src="https://herolifeinsurance.ca/wp-content/uploads/2021/12/money_back_ico.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepFourth;
